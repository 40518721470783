import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  Button,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { uploadImageToMlTrainingSet } from 'api';
import { SnackbarUtils } from 'utils/snackbarUtils';

import { AnomalyScores } from 'components/Controls/AnomaliesScores';
import { SimpleModal } from 'components/Controls';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //marginLeft: theme.spacing(2),
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '10px',
    minWidth: 400,
    maxWidth: 400,
  },
  header: {
    backgroundColor: 'lightgray',
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paperLeft: {
    padding: theme.spacing(1),
    textAlign: 'left',
    paddingLeft: 20,
    color: theme.palette.text.secondary,
  },
  paperRight: {
    padding: theme.spacing(1),
    textAlign: 'right',
    paddingLeft: 20,
    color: theme.palette.text.secondary,
    // textDecoration: 'underline',
  },
  pageTitle: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    fontSize: 24,
    fontWeight: 'bold',
  },
  categoryTitle: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    fontSize: 18,
    fontWeight: 'bold',
  },
  cell: {
    width: '100%',
  },
  thresholdCell: {
    ...theme.legendCell,
    backgroundColor: '#e2f1f8',
  },
  thresholdCellAlt: {
    ...theme.legendCell,
    backgroundColor: '#c1d5e0',
  },
  selectInput: {
    //zIndex: 100,
    marginTop: 16,
    marginBottom: 8,
    marginLeft: 10,
    marginRight: 10,
  },
  paperBold: {
    width: '110px',
    padding: theme.spacing(1),
    fontSize: 14,
    backgroundColor: '#e0ffee',
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  csvLink: {
    marginTop: theme.spacing(2),
  },
  modal: {
    padding: 60,
    position: 'absolute',
    width: 500,
    height: 700,
    display: 'block',
    boxShadow: theme.shadows[5],
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
  },
}));

function AddToTrainingModal({
  client,
  dataSets,
  anomalies,
  selectedImageItem,
  isOpen,
  onClose,
}) {
  console.log('AddToTrainingModal', anomalies);
  console.log('AddToTrainingModal', selectedImageItem);
  const classes = useStyles();

  const [uploadPath, setUploadPath] = useState('');
  const [dataSetName, setDataSetName] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  if (!anomalies) {
    return null;
  }

  function extractScores(data) {
    const excludeKeys = new Set([
      'bucket_name',
      'client',
      'date',
      'id',
      'image_path',
      'model_version',
      'region',
      'scanner',
      'valid',
      'missing',
      'store_id',
      'store',
      'tool_id',
    ]);
    return Object.keys(data)
      .filter((key) => !excludeKeys.has(key))
      .map((key) => ({
        name: key,
        score: data[key],
      }));
  }

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const uploadImageToMlTrainingDataSet = async (data) => {
    setIsUploading(true);
    const response = await uploadImageToMlTrainingSet(
      { ...data, client: client },
      SnackbarUtils,
    );

    if (response) {
      if (onClose) {
        onClose();
      }
    }
    setIsUploading(false);
  };

  return (
    <Card style={{ maxWidth: 700 }}>
      {/* <CardHeader title={'uhanskas'} subheader={'subheaderis'} /> */}
      <Grid container>
        <Grid item xs={12}>
          <CardContent>
            <SimpleModal open={isOpen} handleClose={handleClose}>
              <div className={classes.modal}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    wordWrap: 'break-word',
                    marginBottom: 10,
                  }}
                >
                  Upload image to training set
                </Typography>

                <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
                  <AnomalyScores
                    scores={anomalies ? extractScores(anomalies || {}) : []}
                    showSelection
                    onSelectionChanged={setUploadPath}
                  />
                </Typography>

                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  This image will be uploaded to the '
                  <strong>{uploadPath}</strong>' training set in the S3 bucket
                  at:
                </div>

                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <InputLabel id="dataset-label" style={{ marginTop: -5 }}>
                    Data Set
                  </InputLabel>
                  <Select
                    style={{ width: '100%' }}
                    labelId="dataset-label"
                    id="dataset-checkbox"
                    value={dataSetName}
                    onChange={(e) => setDataSetName(e.target.value)}
                    className={classes.input}
                  >
                    {dataSets?.map((mt) => (
                      <MenuItem key={mt.id} value={mt.id}>
                        <ListItemText primary={mt.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <strong>
                  {`s3://fitskin-ml/training/${dataSetName}/${uploadPath}`}
                </strong>

                <div style={{ marginTop: 20 }}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    disabled={dataSetName === '' || isUploading}
                    onClick={() =>
                      uploadImageToMlTrainingDataSet({
                        dir: dataSetName,
                        subdir: uploadPath,
                        region: selectedImageItem.region,
                        bucket_name: selectedImageItem.bucket_name,
                        image_key: selectedImageItem.image_path,
                      })
                    }
                  >
                    Upload
                  </Button>
                  <Button type="button" color="primary" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              </div>
            </SimpleModal>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}

export default AddToTrainingModal;
