import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CircularLoader } from 'components/Controls';
import BarChartIcon from '@material-ui/icons/BarChart';
import { getAnomaliesJobs } from 'api';
import { Form } from 'components/Controls';
import Select from 'react-select';
import { history } from 'utils/history';
import { Context as AuthContext } from 'contexts/AuthContext';
import { Config } from 'configs';
import { AnomaliesMongoDbDashboardLinks } from 'routes';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';

export const MLXpolScreen = ({ location }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      //marginLeft: theme.spacing(2),
      border: '1px solid rgba(0, 0, 0, 0.12)',
      padding: '10px',
      minWidth: 400,
      maxWidth: 400,
    },
    header: {
      backgroundColor: 'lightgray',
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paperLeft: {
      padding: theme.spacing(1),
      textAlign: 'left',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
    },
    paperRight: {
      padding: theme.spacing(1),
      textAlign: 'right',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
    },
    pageTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 24,
      fontWeight: 'bold',
    },
    categoryTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 18,
      fontWeight: 'bold',
    },
    cell: {
      width: '100%',
    },
    thresholdCell: {
      ...theme.legendCell,
      backgroundColor: '#e2f1f8',
    },
    thresholdCellAlt: {
      ...theme.legendCell,
      backgroundColor: '#c1d5e0',
    },
    selectInput: {
      //zIndex: 100,
      marginTop: 16,
      marginBottom: 8,
      marginLeft: 10,
      marginRight: 10,
    },
    paperBold: {
      width: '110px',
      padding: theme.spacing(1),
      fontSize: 14,
      backgroundColor: '#e0ffee',
      fontWeight: 'bold',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    csvLink: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  const getDashboardLink = (type) => {
    const dashboard = AnomaliesMongoDbDashboardLinks.find(
      (item) => item.client === selectedCustomer?.value,
    );

    if (dashboard) {
      return dashboard.type[type];
    }

    return '';
  };

  const { state: authState } = useContext(AuthContext);

  const [customers] = useState([
    { value: 'wba', label: 'WBA' },
    { value: 'sephora_na', label: 'Sephora NA' },
  ]);
  const [anomaliesJobs, setAnomaliesJobs] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const hasData = anomaliesJobs != null && anomaliesJobs.length > 0;

  const fetchSephoraChart = useCallback(async (chartId, placementId) => {
    setIsLoading(true);

    const sdk = new ChartsEmbedSDK({
      baseUrl: 'https://charts.mongodb.com/charts-warehouse-uhqyz',
    });

    const chart = sdk.createChart({
      chartId: chartId,
      showAttribution: false,
      filter: {},
      autoRefresh: false,
      maxDataAge: 86400,
    });

    await chart.render(document.getElementById(placementId));

    setIsLoading(false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      if (selectedCustomer) {
        const anomalies = await getAnomaliesJobs(selectedCustomer.value);
        setAnomaliesJobs(anomalies || []);

        if (selectedCustomer.value === 'wba') {
          fetchSephoraChart(
            '6572d0ed-415d-444c-8d2f-43ed4bf48ebf',
            'wba_confidence_image_is_valid',
          );
          fetchSephoraChart(
            '6572cc19-37b3-47a7-814d-6cd388418ff0',
            'wba_ratio_valid_invalid',
          );
          fetchSephoraChart(
            '6572d7ac-7ec2-4d6f-80a5-f5f5910f385b',
            'wba_invalid_image_categories',
          );
          fetchSephoraChart(
            '6572dab0-c4af-438e-8e72-2f946a4d36e7',
            'wba_invalid_image_categories_2',
          );
        }
        if (selectedCustomer.value === 'sephora_na') {
          // fetchSephoraChart(
          //   '6572d0ed-415d-444c-8d2f-43ed4bf48ebf',
          //   'sephora_na_confidence_image_is_valid',
          // );
          // fetchSephoraChart(
          //   '6572d0ed-415d-444c-8d2f-43ed4bf48ebf',
          //   'sephora_na_ratio_valid_invalid',
          // );
          // fetchSephoraChart(
          //   '6572d0ed-415d-444c-8d2f-43ed4bf48ebf',
          //   'sephora_na_invalid_image_categories',
          // );
          // fetchSephoraChart(
          //   '6572d0ed-415d-444c-8d2f-43ed4bf48ebf',
          //   'sephora_na_invalid_image_categories_2',
          // );
        }
      }

      setIsLoading(false);
    };
    fetchData();
  }, [selectedCustomer]);

  const compareValues = (jobValue, threshold) => {
    if (!threshold || threshold.value === null) return false;
    switch (threshold.compareBy) {
      case 'lt':
        return jobValue < threshold.value;
      case 'gt':
        return jobValue > threshold.value;
      default:
        return false;
    }
  };

  const getAnomaliesThresholds = (customer) => {
    return {
      valid: { value: 0.75, compareBy: 'lt' },
      out_of_focus: { value: 0.05, compareBy: 'gt' },
      incorrect_placement: { value: 0.05, compareBy: 'gt' },
      foreign_object: { value: 0.01, compareBy: 'gt' },
    };
  };

  const renderThresholdsHeaderAnomalies = () => {
    const thresholds = getAnomaliesThresholds();

    const valid = (thresholds?.valid?.value || 0) * 100;
    // const makeup = (thresholds?.makeup?.value || 0) * 100;
    const out_of_focus = (thresholds?.out_of_focus?.value || 0) * 100;
    const incorrect_placement =
      (thresholds?.incorrect_placement?.value || 0) * 100;
    // const complexion_inflammation =
    //   (thresholds?.complexion_inflammation?.value || 0) * 100;
    // const not_skin = (thresholds?.not_skin?.value || 0) * 100;
    // const hair = (thresholds?.hair?.value || 0) * 100;
    const foreign_object = (thresholds?.foreign_object?.value || 0) * 100;

    return (
      <div style={{ marginTop: 30, marginBottom: 30 }}>
        <div style={{ paddingBottom: 10 }}>
          <Typography className={classes.categoryTitle} align="left">
            Thresholds
          </Typography>
        </div>
        <Grid container spacing={3} style={{ marginLeft: 0 }}>
          <Grid item className={classes.thresholdCell}>
            <span>
              Valid {'<'} {valid.toFixed(2)} %
            </span>
          </Grid>
          {/* <Grid item className={classes.thresholdCellAlt}>
            <span>({makeup} %) Makeup</span>
          </Grid> */}
          <Grid item className={classes.thresholdCellAlt}>
            <span>
              Out of focus {'>'} {out_of_focus.toFixed(2)} %
            </span>
          </Grid>
          <Grid item className={classes.thresholdCell}>
            <span>
              Incorrect placement {'>'} {incorrect_placement.toFixed(2)} %
            </span>
          </Grid>
          {/* <Grid item className={classes.thresholdCell}>
            <span>({complexion_inflammation.toFixed(2)} %) Complexion inflammation</span>
          </Grid>
          <Grid item className={classes.thresholdCellAlt}>
            <span>({not_skin.toFixed(2)} %) Not skin</span>
          </Grid>
          <Grid item className={classes.thresholdCell}>
            <span>({hair.toFixed(2)} %) Hair</span>
          </Grid> */}
          <Grid item className={classes.thresholdCellAlt}>
            <span>
              Foreign object {'>'} {foreign_object.toFixed(2)} %
            </span>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderHeaderAnomalies = () => {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns:
            '180px 80px 120px 120px 120px 150px 180px 120px 120px 120px 120px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <div>
          <Paper className={classes.header}>
            <span>Date</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Model</span>
          </Paper>
        </div>
        <div>
          <Paper
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              history.push(
                `/top-scores?propertyName=valid&type=anomalies&client=${selectedCustomer.value}`,
              );
            }}
            className={classes.header}
          >
            <span>Valid</span>
          </Paper>
        </div>
        <div>
          <Paper
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              history.push(
                `/top-scores?propertyName=makeup&type=anomalies&client=${selectedCustomer.value}`,
              );
            }}
            className={classes.header}
          >
            <span>Makeup</span>
          </Paper>
        </div>
        <div>
          <Paper
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              history.push(
                `/top-scores?propertyName=out_of_focus&type=anomalies&client=${selectedCustomer.value}`,
              );
            }}
            className={classes.header}
          >
            <span>Out of focus</span>
          </Paper>
        </div>
        <div>
          <Paper
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              history.push(
                `/top-scores?propertyName=incorrect_placement&type=anomalies&client=${selectedCustomer.value}`,
              );
            }}
            className={classes.header}
          >
            <span>Incorrect placement</span>
          </Paper>
        </div>
        <div>
          <Paper
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              history.push(
                `/top-scores?propertyName=complexion_inflammation&type=anomalies&client=${selectedCustomer.value}`,
              );
            }}
            className={classes.header}
          >
            <span>Complexion inflammation</span>
          </Paper>
        </div>
        <div>
          <Paper
            onClick={() => {
              history.push(
                `/top-scores?propertyName=not_skin&type=anomalies&client=${selectedCustomer.value}`,
              );
            }}
            className={classes.header}
          >
            <span>Not skin</span>
          </Paper>
        </div>
        <div>
          <Paper
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              history.push(
                `/top-scores?propertyName=hair&type=anomalies&client=${selectedCustomer.value}`,
              );
            }}
            className={classes.header}
          >
            <span>Hair</span>
          </Paper>
        </div>
        <div>
          <Paper
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              history.push(
                `/top-scores?propertyName=foreign_object&type=anomalies&client=${selectedCustomer.value}`,
              );
            }}
            className={classes.header}
          >
            <span>Foreign object</span>
          </Paper>
        </div>
        <div>
          <Paper
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              history.push(
                `/top-scores?propertyName=tattoo&type=anomalies&client=${selectedCustomer.value}`,
              );
            }}
            className={classes.header}
          >
            <span>Tatto</span>
          </Paper>
        </div>
      </div>
    );
  };

  const renderDataAnomalies = (finishedJobs) => {
    const thresholds = getAnomaliesThresholds();

    let rows = [];
    if (finishedJobs && finishedJobs.length > 0) {
      finishedJobs.forEach((job) => {
        let outputCells = [];
        outputCells.push({
          value: job.date.substring(0, 10),
          isAnomalous: false,
        });
        outputCells.push({ value: job.model_version, isAnomalous: false });
        outputCells.push({
          value: job.valid,
          isAnomalous: compareValues(job.valid, thresholds.valid),
          date: job.date.substring(0, 10),
          propertyName: 'valid',
        });
        outputCells.push({
          value: job.makeup,
          isAnomalous: compareValues(job.makeup, thresholds.makeup),
          date: job.date.substring(0, 10),
          propertyName: 'makeup',
        });
        outputCells.push({
          value: job.out_of_focus,
          isAnomalous: compareValues(job.out_of_focus, thresholds.out_of_focus),
          date: job.date.substring(0, 10),
          propertyName: 'out_of_focus',
        });
        outputCells.push({
          value: job.incorrect_placement,
          isAnomalous: compareValues(
            job.incorrect_placement,
            thresholds.incorrect_placement,
          ),
          date: job.date.substring(0, 10),
          propertyName: 'incorrect_placement',
        });
        outputCells.push({
          value: job.complexion_inflammation,
          isAnomalous: compareValues(
            job.complexion_inflammation,
            thresholds.complexion_inflammation,
          ),
          date: job.date.substring(0, 10),
          propertyName: 'complexion_inflammation',
        });
        outputCells.push({
          value: job.not_skin,
          isAnomalous: compareValues(job.not_skin, thresholds.not_skin),
          date: job.date.substring(0, 10),
          propertyName: 'not_skin',
        });
        outputCells.push({
          value: job.hair,
          isAnomalous: compareValues(job.hair, thresholds.hair),
          date: job.date.substring(0, 10),
          propertyName: 'hair',
        });
        outputCells.push({
          value: job.foreign_object,
          isAnomalous: compareValues(
            job.foreign_object,
            thresholds.foreign_object,
          ),
          date: job.date.substring(0, 10),
          propertyName: 'foreign_object',
        });
        outputCells.push({
          value: job.tattoo,
          isAnomalous: compareValues(job.tattoo, thresholds.tattoo),
          date: job.date.substring(0, 10),
          propertyName: 'tattoo',
        });
        rows.push(outputCells);
      });
    }

    return rows.map((row, index) => (
      <div
        key={index}
        style={{
          display: 'grid',
          gridTemplateColumns:
            '180px 80px 120px 120px 120px 150px 180px 120px 120px 120px 120px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        {row.map((cell, cellIndex) => (
          <Grid key={cellIndex} item className={classes.cell}>
            <Paper
              className={cellIndex >= 2 ? classes.paperRight : classes.paper}
              style={{
                backgroundColor: cell.isAnomalous ? '#ffcdd2' : undefined,
                cursor: cellIndex >= 2 ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (cellIndex < 2) return;

                const { date, propertyName } = cell;
                history.push(
                  `/scores-by-date?date=${date}&propertyName=${propertyName}&type=anomalies&client=${selectedCustomer.value}`,
                );
              }}
            >
              {cellIndex >= 2
                ? `${(parseFloat(cell.value) * 100).toFixed(2)}%`
                : cell.value}
            </Paper>
          </Grid>
        ))}
      </div>
    ));
  };

  const renderGrid = (type) => {
    if (!hasData) {
      return null;
    }

    return (
      <div>
        <Grid item spacing={0}>
          <div>{renderThresholdsHeaderAnomalies()}</div>
          <div>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <Typography className={classes.categoryTitle} align="left">
                Last 20 Jobs
              </Typography>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getDashboardLink(type)}
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
              >
                <BarChartIcon style={{ fontSize: '2rem' }} />
              </a>
            </div>
            <Grid item spacing={0}>
              {anomaliesJobs && (
                <>
                  {renderHeaderAnomalies()}
                  {renderDataAnomalies(anomaliesJobs)}
                </>
              )}
            </Grid>
          </div>
        </Grid>
      </div>
    );
  };

  return (
    <div>
      <div>
        <Typography className={classes.pageTitle} align="left">
          XPOL
        </Typography>
      </div>
      <div className={classes.root}>
        <Form>
          <Grid container direction="column">
            {authState.user?.role === Config.ROLES.ADMIN && (
              <Grid item alignItems="center">
                <Select
                  onChange={(selectedItem) => {
                    setSelectedCustomer(selectedItem);
                  }}
                  placeholder="Select client"
                  className={classes.selectInput}
                  options={customers}
                />
              </Grid>
            )}
          </Grid>
        </Form>
      </div>
      <div style={{ marginTop: 30 }}></div>
      <div style={{ marginTop: 10 }}>
        {isLoading && (
          <CircularLoader
            doNotCenter
            customStyle={{
              flexGrow: 1,
              width: '100vw',
            }}
          />
        )}
      </div>
      {!isLoading && renderGrid('anomalies')}
      {selectedCustomer?.value === 'wba' && (
        <>
          <div style={{ display: 'flex', marginTop: 30, gap: '20px' }}>
            <div
              id="wba_confidence_image_is_valid"
              style={{
                width: '500px',
                height: '550px',
                display: isLoading ? 'none' : 'block',
              }}
            />
            <div
              id="wba_ratio_valid_invalid"
              style={{
                width: '500px',
                height: '550px',
                display: isLoading ? 'none' : 'block',
              }}
            />
          </div>

          <div style={{ display: 'flex', marginTop: 30, gap: '20px' }}>
            <div
              id="wba_invalid_image_categories"
              style={{
                width: '500px',
                height: '550px',
                display: isLoading ? 'none' : 'block',
              }}
            />
            <div
              id="wba_invalid_image_categories_2"
              style={{
                width: '600px',
                height: '550px',
                display: isLoading ? 'none' : 'block',
              }}
            />
          </div>
        </>
      )}

      {selectedCustomer?.value === 'sephora_na' && (
        <>
          <div style={{ display: 'flex', marginTop: 30, gap: '20px' }}>
            <div
              id="sephora_na_confidence_image_is_valid"
              style={{
                width: '400px',
                height: '450px',
                display: isLoading ? 'hidden' : 'block',
              }}
            />
            <div
              id="sephora_na_ratio_valid_invalid"
              style={{
                width: '400px',
                height: '450px',
                display: isLoading ? 'hidden' : 'block',
              }}
            />
          </div>
          <div style={{ display: 'flex', marginTop: 30, gap: '20px' }}>
            <div
              id="sephora_na_invalid_image_categories"
              style={{
                width: '400px',
                height: '450px',
                display: isLoading ? 'hidden' : 'block',
              }}
            />
            <div
              id="sephora_na_invalid_image_categories_2"
              style={{
                width: '400px',
                height: '450px',
                display: isLoading ? 'hidden' : 'block',
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};
