import React from 'react';
import { Typography } from '@mui/material';
import PortalLink from 'components/portalLink';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paperLeft: {
    padding: theme.spacing(1),
    textAlign: 'left',
    paddingLeft: 20,
    color: theme.palette.text.secondary,
  },
  paperRight: {
    padding: theme.spacing(1),
    textAlign: 'right',
    paddingLeft: 20,
    color: theme.palette.text.secondary,
    // textDecoration: 'underline',
  },
}));

const AnomalyItem = ({ item, highlightedProperty }) => {
  const classes = useStyles();
  const underlineStyle = {
    // textDecoration: 'underline',
  };

  const getStyle = (propertyName) => null;
  // highlightedProperty === propertyName ? underlineStyle : null;

  return (
    <>
      <Typography className={classes.paperLeft}>
        <PortalLink image={item} />
      </Typography>
      <Typography className={classes.paperLeft}>
        Model: {item.model_version}
      </Typography>
      <Typography className={classes.paperLeft} style={getStyle('valid')}>
        Valid: {(parseFloat(item.valid) * 100).toFixed(2)}%
      </Typography>
      <Typography className={classes.paperLeft} style={getStyle('makeup')}>
        Makeup: {(parseFloat(item.makeup) * 100).toFixed(2)}%
      </Typography>
      <Typography
        className={classes.paperLeft}
        style={getStyle('out_of_focus')}
      >
        Out Of Focus: {(parseFloat(item.out_of_focus) * 100).toFixed(2)}%
      </Typography>
      <Typography
        className={classes.paperLeft}
        style={getStyle('incorrect_placement')}
      >
        Incorrect Placement:{' '}
        {(parseFloat(item.incorrect_placement) * 100).toFixed(2)}%
      </Typography>
      <Typography
        className={classes.paperLeft}
        style={getStyle('complexion_inflammation')}
      >
        Complexion Inflammation:{' '}
        {(parseFloat(item.complexion_inflammation) * 100).toFixed(2)}%
      </Typography>
      <Typography className={classes.paperLeft} style={getStyle('not_skin')}>
        Not Skin: {(parseFloat(item.not_skin) * 100).toFixed(2)}%
      </Typography>
      <Typography className={classes.paperLeft} style={getStyle('hair')}>
        Hair: {(parseFloat(item.hair) * 100).toFixed(2)}%
      </Typography>
      <Typography
        className={classes.paperLeft}
        style={getStyle('foreign_object')}
      >
        Foreign Object: {(parseFloat(item.foreign_object) * 100).toFixed(2)}%
      </Typography>
      <Typography className={classes.paperLeft} style={getStyle('tattoo')}>
        Tattoo: {(parseFloat(item.tattoo) * 100).toFixed(2)}%
      </Typography>
    </>
  );
};

export default AnomalyItem;
