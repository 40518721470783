/* eslint-disable radix */
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { CircularLoader } from 'components/Controls';
// import { getScans, getScores } from 'api';
import { getScans } from 'api';
import Paper from '@material-ui/core/Paper';
import { useForm } from 'react-hook-form';
import { CSVLink } from 'react-csv';
import { buildQueryString } from 'utils/queryStringUtils';
import queryString from 'query-string';
import { Filter } from 'views/controls/scansFilter';
import { CustomerFilter } from 'views/controls/customerFilter';
import { Context as AuthContext } from 'contexts/AuthContext';
import { Config } from 'configs';
import { getMonths } from 'views/controls/months';

export const StatisticsScansDataScreen = ({ location }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    pageTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 24,
      fontWeight: 'bold',
    },
    header: {
      backgroundColor: 'lightgray',
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paperBold: {
      padding: theme.spacing(1),
      fontSize: 14,
      fontWeight: 'bold',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    row: {
      height: 40,
      width: '100%',
      textAlign: 'center',
    },
    allScansTotalCellEmpty: {
      height: 60,
      width: '100%',
      textAlign: 'end',
      backgroundColor: '#FFFFFF',
    },
    allScansTotalCell: {
      height: 60,
      width: '100%',
      textAlign: 'center',
      backgroundColor: '#e0ffee',
    },
    totals: {
      height: 40,
      width: '100%',
      textAlign: 'center',
      backgroundColor: '#e0ffee',
    },
    totalsFirstRow: {
      height: 60,
      width: '100%',
      textAlign: 'center',
      backgroundColor: '#e0ffee',
    },
    rowWithValue: {
      backgroundColor: '#F8F8F8',
    },
    tool: {
      height: 40,
      width: '100%',
      textAlign: 'center',
      justifyContent: 'center',
    },
    storeNameHeader: {
      backgroundColor: 'lightgray',
      padding: theme.spacing(1),
    },
    storeName: {
      height: 40,
      width: '100%',
      textAlign: 'left',
      paddingLeft: 5,
      justifyContent: 'center',
    },
    storeNameLink: {
      textDecoration: 'none',
    },
    labelAction: {
      color: theme.palette.text.primary,
    },
  }));
  const classes = useStyles();

  const currentMonth = new Date().getMonth() + 1;

  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [csvResultData, setCsvResultData] = useState(null);
  const [csvResultHeader, setCsvResultHeader] = useState(null);
  const [csvWbaScoresData, setCsvWbaScoresData] = useState([]);
  const [isFilterVisible, setIsFilterVisible] = useState(null);
  const [selectedYear, setSelectedYear] = useState(2024);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedReportType, setSelectedReportType] = useState();
  const [dataDisplayMonth, setDataDisplayMonth] = useState(currentMonth);
  const [selectedClient, setSelectedClient] = useState(null);
  const { state: authState } = useContext(AuthContext);

  // filtering data
  const { register, handleSubmit, control, getValues } = useForm();

  // history
  const history = useHistory();

  const isCustomerWBA = false;
  // (Config.AUTH.WBA.ID === authState.user?.id ||
  //   Config.AUTH.ULTA.ID === authState.user?.id) &&
  // authState.user?.role === Config.ROLES.CUSTOMER;

  const isCustomerSephora = false;
  // (Config.AUTH.SEPHORA_NA.ID === authState.user?.id ||
  //   Config.AUTH.SEPHORA_EMEA.ID === authState.user?.id ||
  //   Config.AUTH.SEPHORA_SEA.ID === authState.user?.id) &&
  // authState.user?.role === Config.ROLES.CUSTOMER;

  useEffect(() => {
    const prepareCsvDataForDayView = async () => {
      const daysInMonth = getDaysInMonth(selectedYear, selectedMonth);
      const header = prepareDataToRenderMonthDaysForExport({
        days: daysInMonth,
      });

      const data = renderData(response, true);

      setCsvResultData(data || '');
      setCsvResultHeader(header || '');
    };

    if (response) {
      prepareCsvDataForDayView();
    } else {
      setCsvResultData(null);
    }
  }, [response, dataDisplayMonth, selectedMonth, selectedYear]);

  // Fetches scores data for WBA - This only applied for WBA
  //   useEffect(() => {
  //     (async function fetchScores() {
  //       if (isCustomerWBA && displayMode === Config.DISPLAY_MODE.DAILY) {
  //         const scores = await getScores(source, selectedYear, selectedMonth);
  //         setCsvWbaScoresData(scores);
  //       }
  //     })();
  //   }, [displayMode, selectedYear, selectedMonth, isCustomerWBA, source]);

  // Show filter. When admin - show advanced filter. When customer - show customer (custom) filter
  useEffect(() => {
    setIsFilterVisible(
      authState.user?.role === Config.ROLES.ADMIN ? true : false,
    );
  }, [authState]);

  const fetch = useCallback(async () => {
    setIsLoading(true);

    const qs = queryString.parse(location.search);

    const { client, month, year, reportMode, item } = qs;

    if (month) {
      setSelectedMonth(month);
    }
    if (year) {
      setSelectedYear(year);
    }
    setDataDisplayMonth(month || currentMonth);

    let reportType = reportMode || Config.REPORT_TYPE.SCANNERS;

    setSelectedReportType(reportType);

    // if (isCustomerWBA) {
    //   reportType = Config.REPORT_TYPE.SCANNERS_AND_STORES;
    // }

    if (client) {
      setSelectedClient(client);
      if (
        client !== authState.user?.id &&
        authState.user?.role === Config.ROLES.CUSTOMER
      ) {
        setResponse(null);
        // setCsvResultData(null);
      } else if (client !== '-1') {
        // if (isCustomerSephora(parseInt(customer))) {
        //   reportType = Config.REPORT_TYPE.STORES;
        // }
        console.log('fetching data for customer', {
          client,
          year,
          month,
          reportType,
          item,
        });
        const scansResult = await getScans(
          client,
          year,
          month,
          reportType,
          item,
        );

        if (scansResult) {
          setResponse(scansResult);
          // prepareCsvData();
        }
      } else {
        setResponse(null);
        // setCsvResultData(null);
      }
    } else {
      // We look at supervisor portal as from admin perspective
      // for this case customer filer is not set and no call is needed
      if (authState.user?.id === -1) {
        setResponse(null);
      } else {
        // const scansResult = await getScans(
        //   authState.user?.id,
        //   dMode,
        //   year,
        //   month,
        //   source,
        //   reportType,
        //   authState.token,
        // );
        // if (scansResult && scansResult.result) {
        //   // await fetchStoreAndScannerPairs();
        //   setResponse(scansResult.result);
        //   // prepareCsvData();
        // }
      }
    }

    setIsLoading(false);
  }, [location.search, authState.user]);

  // const fetchStoreAndScannerPairs = useCallback(async () => {
  //   const pairs = await getStoreAndScannerPairs(authState.user?.id);
  //   if (pairs) {
  //     setScannerPairs(pairs);
  //   }
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    fetch();
  }, [fetch]);

  const onFilterClicked = (filterFormData) => {
    const query = buildQueryString(filterFormData);

    console.log('filterFormData', filterFormData);

    setDataDisplayMonth(filterFormData.month || currentMonth);

    history.push({
      pathname: '/statistics/scans/data',
      search: `?${query}`,
    });
  };

  const onFilterReset = () => {
    history.push({
      pathname: '/statistics/scans/data',
      search: ``,
    });
    setResponse(null);
  };

  const getDaysInMonth = (yearFromFilter, monthFromFilter) => {
    const currentDate = new Date();
    let currentYear = yearFromFilter
      ? parseInt(yearFromFilter)
      : currentDate.getFullYear();
    let currentMonth = currentDate.getMonth() + 1;
    return new Date(currentYear, monthFromFilter || currentMonth, 0).getDate();
  };

  const prepareDataToRenderMonthDays = (days, itemScansOnDay) => {
    if (!itemScansOnDay) {
      return null;
    }

    let daysRendered = Array.from(Array(days).keys()).map((day) => {
      const dayNumber = day + 1;

      let itemScansOnThatDay =
        itemScansOnDay.days.find((x) => x.day === dayNumber)?.total || 0;

      let cellClass =
        itemScansOnThatDay && itemScansOnThatDay > 0
          ? classes.rowWithValue
          : classes.row;

      return (
        <div key={`day-${dayNumber}`}>
          <Paper className={cellClass}>
            <span>{itemScansOnThatDay}</span>
          </Paper>
        </div>
      );
    });

    // Add total scans cell at the beginning of the row
    daysRendered.unshift(
      <div key="total-scans">
        <Paper className={classes.totals}>
          <span style={{ height: '40px' }}>
            {itemScansOnDay.total_item_scans}
          </span>
        </Paper>
      </div>,
    );

    daysRendered.unshift(
      <div key="item-id">
        <Paper className={classes.tool}>
          {itemScansOnDay.item_id || 'N/A'}
        </Paper>
      </div>,
    );
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `150px repeat(${days + 2}, 65px)`,
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '40px',
          verticalAlign: 'middle',
        }}
      >
        {daysRendered}
      </div>
    );
  };

  const prepareDataToRenderMonthDaysForExport = ({
    days,
    fn,
    monthDaysSet,
  }) => {
    let daysRendered = Array.from(Array(days).keys()).map((day) => {
      const dayNumber = day + 1;
      return (fn && fn(dayNumber, monthDaysSet)) || dayNumber.toString();
    });
    if (monthDaysSet && monthDaysSet.item_id) {
      daysRendered.unshift(monthDaysSet.item_id.toString());

      // if (isCustomerWBA) {
      //   // daysRendered.unshift(getStoreNameByScannerId(monthDaysSet.tool_id));
      //   daysRendered.unshift(monthDaysSet.store_id);
      // }
    } else {
      daysRendered.unshift(new Date().getFullYear() + '-' + dataDisplayMonth);
      // if (isCustomerWBA) {
      //   daysRendered.unshift('');
      // }
    }

    return daysRendered;
  };

  const renderHeader = () => {
    const daysInMonth = getDaysInMonth(selectedYear, selectedMonth);
    let daysRendered = Array.from(Array(daysInMonth).keys()).map((day) => {
      const dayNumber = day + 1;
      let cellClass = classes.header;

      return (
        <div>
          <Paper className={cellClass}>
            <span>{dayNumber}</span>
          </Paper>
        </div>
      );
    });

    daysRendered.unshift(
      <div>
        <Paper className={classes.header}>
          <span>&nbsp;</span>
        </Paper>
      </div>,
    );

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '215px repeat(33, 65px)',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '40px',
          verticalAlign: 'middle',
        }}
      >
        {daysRendered}
      </div>
    );
  };

  const renderHeaderTotals = (daysInMonth, result) => {
    let daysRendered = Array(daysInMonth)
      .fill(0)
      .map((_, index) => {
        const { total_scans: scans, total_items: scannerOrStore } =
          result.totals.find((x) => x.day === index + 1) || {
            total_scans: 0,
            total_items: 0,
          };

        return (
          <div key={`day-${index}`}>
            <Paper className={classes.totalsFirstRow}>
              <span
                style={{
                  height: '40px',
                }}
              >
                <div>{(scans || 0).toLocaleString('en-US')}</div>
                <div style={{ height: '10px' }}>
                  <span
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    {scannerOrStore === 0 ? '-' : scannerOrStore}{' '}
                    {Config.REPORT_TYPE.SCANNERS === selectedReportType
                      ? 'tl.'
                      : 'st.'}
                  </span>
                </div>
              </span>
            </Paper>
          </div>
        );
      });

    daysRendered.unshift(
      <div>
        <Paper className={classes.allScansTotalCell}>
          <span
            style={{
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <div>
              {result?.total_monthly_scans?.toLocaleString('en-US') || 0} Scans.{' '}
            </div>
            <div>
              {result?.total_monthly_items?.toLocaleString('en-US') || 0}
              {Config.REPORT_TYPE.SCANNERS === selectedReportType
                ? 'Scanners (t.)'
                : ' Stores (st.)'}{' '}
              &#8681;
            </div>
          </span>
        </Paper>
      </div>,
    );

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '215px repeat(33, 65px)',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '30px',
          verticalAlign: 'middle',
        }}
      >
        {daysRendered}
      </div>
    );
  };

  const renderData = (daysInMonth, result, forExport) => {
    let rows = [];
    if (result && result.most_used_items && result.most_used_items.length > 0) {
      result.most_used_items.forEach((item) => {
        // const row = forExport
        //   ? prepareDataToRenderMonthDaysForExport(renderRequest)
        //   : prepareDataToRenderMonthDays(daysInMonth, item);

        const row = prepareDataToRenderMonthDays(daysInMonth, item);

        rows.push(row);
      });
    }

    // Add totals row
    // if (!forExport) {
    //   const totalsRow = constructTotalScansRow(totals);
    //   rows.unshift(totalsRow);
    // }

    return rows.map((r) => r);
  };

  const renderGrid = (response) => {
    const daysInMonth = getDaysInMonth(selectedYear, selectedMonth);
    return (
      <div>
        {renderHeader(daysInMonth)}
        {renderHeaderTotals(daysInMonth, response)}
        {renderData(daysInMonth, response)}
      </div>
    );
  };

  const fullWidthGrid = () => {
    if (!response) {
      return <div>No data</div>;
    }

    if (response.total_scans === 0) {
      return <div>No data</div>;
    }

    return (
      <div>
        {/* <Grid className={classes.filterItem} item>
          {csvResultData &&
            csvResultHeader &&
            displayMode === Config.DISPLAY_MODE.DAILY && (
              <CSVLink
                filename={`${getMonthName(dataDisplayMonth)}.csv`}
                data={csvResultData}
                headers={csvResultHeader}
              >
                <Typography className={classes.labelAction} align="left">
                  Export {getMonthName(dataDisplayMonth)} data to CSV
                </Typography>
              </CSVLink>
            )}
        </Grid>
        <Grid className={classes.filterItem} item>
          {isCustomerWBA &&
            csvWbaScoresData &&
            displayMode === Config.DISPLAY_MODE.DAILY &&
            source !== undefined && (
              <CSVLink
                filename={`${getMonthName(dataDisplayMonth)}-scores.csv`}
                data={csvWbaScoresData}
              >
                <Typography className={classes.labelAction} align="left">
                  Export {getMonthName(dataDisplayMonth)} L.b.a scores to CSV
                </Typography>
              </CSVLink>
            )}
        </Grid> */}
        <Grid container spacing={0}>
          {renderGrid(response)}
        </Grid>
      </div>
    );
  };

  // rendering
  return (
    <div className={classes.root}>
      <div>
        <Typography className={classes.pageTitle} align="left">
          Scan Sessions
        </Typography>
      </div>
      {authState.user?.role === Config.ROLES.ADMIN && (
        <Grid container direction="column">
          <Grid className={classes.filterItem} item>
            <Filter
              client={selectedClient}
              filter={onFilterClicked}
              reset={onFilterReset}
              register={register}
              handleSubmit={handleSubmit}
              location={location}
              control={control}
              defaultMonth={selectedMonth}
              defaultYear={selectedYear}
              getValues={getValues}
              reportType={selectedReportType}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      )}
      {authState.user?.role === Config.ROLES.CUSTOMER && (
        <Grid container direction="column">
          <Grid className={classes.filterItem} item>
            <CustomerFilter
              customer={authState.user?.id}
              customerName={authState.user?.customer}
              filter={onFilterClicked}
              reset={onFilterReset}
              register={register}
              handleSubmit={handleSubmit}
              location={location}
              control={control}
              defaultMonth={selectedMonth}
              defaultYear={selectedYear}
              getValues={getValues}
            />
          </Grid>
        </Grid>
      )}
      <div style={{ marginTop: 30 }}></div>
      {!isLoading && fullWidthGrid()}
      {isLoading && <CircularLoader />}
    </div>
  );
};
