/* eslint-disable indent */
import { Config } from 'configs';
import { changeLanguage, translate as t } from 'utils/translate';
import { SnackbarUtils } from 'utils/snackbarUtils';
import { history } from 'utils/history';
import * as API from 'api';
import { createDataContext } from './createDataContext';

const reducer = (state, action) => {
  switch (action.type) {
    case 'set_loading':
      return { ...state, isLoading: action.payload };
    case 'set_initialized':
      return { ...state, initialized: true };
    case 'signin':
      return {
        isLoading: state.isLoading,
        isSocialLoading: state.isSocialLoading,
        errorMessage: null,
        token: action.payload.token,
        user: action.payload.user,
        isSigned: true,
        initialized: true,
      };
    case 'logout':
      return {
        isSigned: false,
        errorMessage: null,
        user: null,
        token: null,
        initialized: true,
      };
    case 'set_error':
      return { ...state, errorMessage: action.payload };
    case 'clear_error':
      return { ...state, errorMessage: null };
    default:
      return state;
  }
};

const initService = (dispatch) => async () => {
  const token = localStorage.getItem('token');

  const localUser = JSON.parse(localStorage.getItem('user'));
  const userId = localUser?.id ?? null;

  let language = localStorage.getItem('language');

  if (!token) {
    if (!language) {
      language = 'en';
      localStorage.setItem('language', language);
    }

    changeLanguage(language);
    dispatch({ type: 'set_initialized' });

    return;
  }

  try {
    const userInfo = await API.getUserInfo({ userId, token });

    const isAdmin = userInfo.roles.find((role) => role === 'sysadmin');

    const userName =
      !!userInfo.firstName || !!userInfo.lastName
        ? `${userInfo.firstName} ${userInfo.lastName}`
        : userInfo.email;

    const user = {
      // eslint-disable-next-line no-underscore-dangle
      id: userInfo.id,
      userName: userInfo.email,
      displayName: userName,
      role: isAdmin ? Config.ROLES.ADMIN : Config.ROLES.AUTHENTICATED,
      language: language ?? 'en',
      permissions: userInfo.permissions,
    };

    const userDataAsString = JSON.stringify(user);

    localStorage.setItem('user', userDataAsString);
    changeLanguage(language ?? 'en');

    dispatch({ type: 'signin', payload: { token, user } });
  } catch (e) {
    if (e.name === 'Forbidden') {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    }
  }

  dispatch({ type: 'set_initialized' });
};

const signin =
  (dispatch) =>
  async ({ email, password }, to) => {
    dispatch({ type: 'set_loading', payload: true });

    try {
      const response = await API.login({ email, password });
      const token = response.access_token;

      localStorage.setItem('token', token);
      const { userId } = response;

      const userInfo = await API.getUserInfo({ userId, token });

      const isAdmin = userInfo.roles.find((role) => role === 'sysadmin');

      const userName =
        !!userInfo.firstName || !!userInfo.lastName
          ? `${userInfo.firstName} ${userInfo.lastName}`
          : userInfo.email;

      const user = {
        // eslint-disable-next-line no-underscore-dangle
        id: userInfo.id,
        userName: userInfo.email,
        displayName: userName,
        role: isAdmin ? Config.ROLES.ADMIN : Config.ROLES.AUTHENTICATED,
        permissions: userInfo.permissions,
      };

      const userDataAsString = JSON.stringify(user);

      localStorage.setItem('user', userDataAsString);

      dispatch({ type: 'signin', payload: { token, user } });

      history.replace(to);
    } catch (e) {
      if (e.name === 'Unauthorized') {
        dispatch({ type: 'set_error', payload: t('login_fail') });
      } else if (e.name === 'Forbidden') {
        dispatch({ type: 'set_error', payload: t('illegal_action') });
      } else if (e.name === 'UserDeactivated') {
        dispatch({ type: 'set_error', payload: t('account_deactivated') });
      } else dispatch({ type: 'set_error', payload: t('unknown_error') });
    } finally {
      dispatch({ type: 'set_loading', payload: false });
    }
  };

const logout = (dispatch) => async (to) => {
  const token = localStorage.getItem('token');

  try {
    await API.logout({ token });

    localStorage.removeItem('token');
    localStorage.removeItem('user');
    dispatch({ type: 'logout' });

    SnackbarUtils.success(t('logout_success'));
    history.replace(to);
  } catch (e) {
    SnackbarUtils.error(t('unknown_error'));
  }
};

const clearErrorMessage = (dispatch) => () => {
  dispatch({ type: 'set_error', payload: null });
};

/*
const passwordReset = (dispatch) => async ({ secretKey, email }) => {
  dispatch({ type: 'set_loading', payload: true });

  try {
    await iam.createPasswordReset({
      secretKey: secretKey || config.SERVICE_USER_KEY,
      email,
    });
  } catch {
    SnackbarUtils.error(t('unknown_error'));
  } finally {
    dispatch({ type: 'set_loading', payload: false });
    SnackbarUtils.success(t('password_reset_send'));
  }
};

const changePassword = (dispatch) => async ({
  secretKey,
  currentPassword,
  newPassword,
  confirmedPassword,
}) => {
  if (newPassword !== confirmedPassword) {
    dispatch({ type: 'set_error', payload: t('confirm_password_error') });

    return;
  }

  try {
    dispatch({ type: 'set_loading', payload: true });
    await iam.updatePassword({
      secretKey: secretKey || config.SERVICE_USER_KEY,
      currentPassword,
      password: newPassword,
      repeatedPassword: confirmedPassword,
    });
    SnackbarUtils.success(t('password_changed_success'));
    dispatch({ type: 'clear_error' });
  } catch (e) {
    if (e.name === 'BadRequest') {
      dispatch({ type: 'set_error', payload: t('old_password_error') });
    } else {
      SnackbarUtils.error('unkown_error');
    }
  } finally {
    dispatch({ type: 'set_loading', payload: false });
  }
};
*/

export const { Context, Provider } = createDataContext(
  reducer,
  {
    initService,
    signin,
    logout,
    clearErrorMessage,
    // passwordReset,
    // changePassword,
  },
  {
    isSigned: false,
    error: null,
    errorMessage: null,
    user: null,
    token: null,
    isLoading: false,
    isSocialLoading: false,
    innerLoading: null,
    refresher: 0,
    initialized: false,
  },
);
