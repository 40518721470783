import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Link, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CircularLoader } from 'components/Controls';
import { getUploads } from 'api';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';

// import { getCustomers } from './controls/customers';
// import MyMongoChart from './mongo';

export const AnomaliesMenuScreen = ({ location }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: '10px',
    },
    header: {
      backgroundColor: 'lightgray',
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    headerRight: {
      backgroundColor: 'lightgray',
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
      textAlign: 'right',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paperLeft: {
      padding: theme.spacing(1),
      textAlign: 'left',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
    },
    paperRight: {
      padding: theme.spacing(1),
      textAlign: 'right',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
    },
    pageTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 24,
      fontWeight: 'bold',
    },
    categoryTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 18,
      fontWeight: 'bold',
    },
    cell: {
      width: '100%',
    },
    thresholdCell: {
      ...theme.legendCell,
      backgroundColor: '#e2f1f8',
    },
    thresholdCellAlt: {
      ...theme.legendCell,
      backgroundColor: '#c1d5e0',
    },
    selectInput: {
      //zIndex: 100,
      marginTop: 16,
      marginBottom: 8,
      marginLeft: 10,
      marginRight: 10,
    },
    paperBold: {
      width: '110px',
      padding: theme.spacing(1),
      fontSize: 14,
      backgroundColor: '#e0ffee',
      fontWeight: 'bold',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    csvLink: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <Typography className={classes.pageTitle} align="left">
          Anomalies
        </Typography>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '280px 380px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <div>
          <Paper className={classes.header}>
            <span>Report</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Description</span>
          </Paper>
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '280px 380px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link href="/anomalies/daily">Daily Report</Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            See anomalies taken from the nightly inference jobs
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link href="/anomalies/monthly">Monthly Report</Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            See anomalies taken from the monthly inference
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link href="/anomalies/feedback">Feedback</Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>Feedback provided from BA</Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link href="/anomalies/data-sets">Data Sets</Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>Custom data sets for training</Paper>
        </Grid>
      </div>
    </div>
  );
};
