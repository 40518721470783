import React from 'react';
import { Router as BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { Provider as AuthProvider } from 'contexts/AuthContext';
import { Provider as PreferencesProvider } from 'contexts/PreferencesContext';
import { history } from 'utils/history';
import { Router } from 'components/Router';
import { routes } from 'routes';
import { Helmet } from 'react-helmet';
import { AxiosInterceptor } from 'components/API';

// https://www.materialpalette.com/colors
const theme = createTheme({
  overrides: {
    MuiImageListItem: {
      imgFullWidth: {
        top: 0,
        transform: 'unset',
      },
    },
  },
  typography: {
    h1: {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '1rem',
    },
    body1: {
      fontSize: '1rem',
    },
  },
  // palette: {
  //   primary: {
  //     main: indigo[800],
  //   },
  //   secondary: {
  //     main: indigo[400],
  //   },
  // },
});

export const App = () => (
  <>
    <CssBaseline />
    <BrowserRouter history={history}>
      <AxiosInterceptor>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <PreferencesProvider>
              <Helmet>
                <title>{process.env.REACT_APP_NAME || 'Scan Portal'}</title>
              </Helmet>
              <Router routes={routes} />
            </PreferencesProvider>
          </AuthProvider>
        </ThemeProvider>
      </AxiosInterceptor>
    </BrowserRouter>
  </>
);
