import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CircularLoader } from 'components/Controls';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';

export const StatisticsScansChartsScreen = ({ location }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: '10px',
    },
    header: {
      backgroundColor: 'lightgray',
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    headerRight: {
      backgroundColor: 'lightgray',
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
      textAlign: 'right',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paperLeft: {
      padding: theme.spacing(1),
      textAlign: 'left',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
    },
    paperRight: {
      padding: theme.spacing(1),
      textAlign: 'right',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
    },
    pageTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 24,
      fontWeight: 'bold',
    },
    categoryTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 18,
      fontWeight: 'bold',
    },
    cell: {
      width: '100%',
    },
    thresholdCell: {
      ...theme.legendCell,
      backgroundColor: '#e2f1f8',
    },
    thresholdCellAlt: {
      ...theme.legendCell,
      backgroundColor: '#c1d5e0',
    },
    selectInput: {
      //zIndex: 100,
      marginTop: 16,
      marginBottom: 8,
      marginLeft: 10,
      marginRight: 10,
    },
    paperBold: {
      width: '110px',
      padding: theme.spacing(1),
      fontSize: 14,
      backgroundColor: '#e0ffee',
      fontWeight: 'bold',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    csvLink: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  const [isLoadingTotals, setIsLoadingTotals] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchSephoraChart = useCallback(async () => {
    setIsLoading(true);

    const sdk = new ChartsEmbedSDK({
      baseUrl: 'https://charts.mongodb.com/charts-warehouse-uhqyz',
    });
    const chart = sdk.createChart({
      chartId: 'abc350c1-9e81-48bc-a704-1df51a6eda26',
      height: '700px',
      showAttribution: false,
      filter: {}, //chartFilter,
      autoRefresh: false,
      maxDataAge: 86400,
    });

    await chart.render(document.getElementById('sephora_na_chart'));

    setIsLoading(false);
  }, []);

  const fetchWbaChart = useCallback(async () => {
    setIsLoading(true);

    const sdk = new ChartsEmbedSDK({
      baseUrl: 'https://charts.mongodb.com/charts-warehouse-uhqyz',
    });
    const chart = sdk.createChart({
      chartId: 'ce30f5b4-f81f-4e45-8f3f-10d6fe9543de', // Optional: ~REPLACE~ with the Chart ID from your Embed Chart dialog
      height: '700px',
      showAttribution: false,
      filter: {}, //chartFilter,
      autoRefresh: false,
      maxDataAge: 86400,
    });

    await chart.render(document.getElementById('wba_chart'));

    setIsLoading(false);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);

    fetchSephoraChart();
    fetchWbaChart();
    setIsLoading(false);
  }, [fetchWbaChart, fetchSephoraChart]);

  return (
    <div className={classes.root}>
      <div>
        <Typography className={classes.pageTitle} align="left">
          Scan Sessions
        </Typography>
      </div>
      {isLoadingTotals && <CircularLoader />}

      <div
        id="sephora_na_chart"
        style={{
          width: '100%',
          height: '700px',
          display: isLoading ? 'hidden' : 'block',
        }}
      />
      <div
        id="wba_chart"
        style={{
          width: '100%',
          height: '700px',
          display: isLoading ? 'hidden' : 'block',
        }}
      />
    </div>
  );
};
