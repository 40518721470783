import React, { useContext, useEffect, useState } from 'react';
import { parse } from 'path-browserify';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getSkinAreaTitle, getSourceTypeTitle } from 'utils/app';
import { Config } from 'configs';
import { Button } from '@material-ui/core';
import { SimpleModal } from './SimpleModal';
import { makeStyles } from '@material-ui/core/styles';
import { Context as AuthContext } from 'contexts/AuthContext';
import { AnomalyScores } from './AnomaliesScores';
import { uploadImageToMlTrainingSet } from 'api/ml';
import { SnackbarUtils } from 'utils/snackbarUtils';
import { trimToTwoDecimals } from 'utils/numberUtils';

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: 60,
    position: 'absolute',
    width: 500,
    height: 700,
    display: 'block',
    boxShadow: theme.shadows[5],
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
  },
}));

export const ReviewCard = ({ item }) => {
  const [loaded, setLoaded] = useState(false);
  const { imageUrl, imageBase64, scores, scanId, image } = item || {
    imageUrl: 'http://url_to_no_image.png',
    imageBase64: null,
    scores: null,
    scanId: null,
    image: null,
  };

  const isXpolImage = item?.image?.type === 'source_xpol';
  const isUvImage = item?.image?.type === 'source_uv';

  const imageDimmensions = Config.IMAGES.PREVIEW_SIZE;
  const [width, height] = imageDimmensions.split('x').map(Number);

  const xpolDimensions = Config.IMAGES.XPOL_PREVIEW_SIZE;
  const [xpolWidth, xpolHeight] = xpolDimensions.split('x').map(Number);
  const xpolMedia = { width: xpolWidth, height: xpolHeight };

  const { state } = useContext(AuthContext);
  const { user, token } = state;
  const isAdmin = user.role === Config.ROLES.ADMIN;
  const hasMlPermissions =
    user.permissions.includes('ml:tools:read') || isAdmin;
  const hasMlUploadPermissions = user.permissions.includes('ml:tools:write');
  const canUploadImageToMLTrainingSet =
    (isAdmin || hasMlUploadPermissions) && (isXpolImage || isUvImage);
  const [uploadPath, setUploadPath] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const classes = useStyles();

  const handleImageLoad = () => {
    setLoaded(true);
  };

  const [anomalyScores, setAnomalyScores] = useState([]);
  const [uvScores, setUvScores] = useState([]);
  const [
    showImageUploadToTrainingSetModal,
    setShowImageUploadToTrainingSetModal,
  ] = useState(false);

  useEffect(() => {
    if (!item.anomalies) {
      return;
    }

    const anomalyKeys = Object.keys(item.anomalies);
    const anomalyArr = anomalyKeys.map((key) => {
      return { name: key, score: item.anomalies[key] || null };
    });

    const sortedArr = anomalyArr.sort(
      (a, b) => parseFloat(b.score) - parseFloat(a.score),
    );

    setAnomalyScores(sortedArr);
  }, [item.anomalies]);

  useEffect(() => {
    if (!item.anomalies_uv) {
      return;
    }

    const anomalyKeys = Object.keys(item.anomalies_uv);
    const anomalyArr = anomalyKeys.map((key) => {
      return { name: key, score: item.anomalies_uv[key] || null };
    });

    const sortedArr = anomalyArr.sort(
      (a, b) => parseFloat(b.score) - parseFloat(a.score),
    );

    setUvScores(sortedArr);
  }, [item.anomalies_uv]);

  const getFileNameFromS3Url = (url) => {
    if (!url) {
      return '';
    }

    const parsedUrl = parse(url);
    const fileName = parsedUrl.base;
    return fileName;
  };

  const uploadImageToMlTrainingDataSet = async (url, dir) => {
    setIsUploading(true);
    const response = await uploadImageToMlTrainingSet({
      url,
      model: isXpolImage ? 'anomalies' : 'uv',
      dir,
      token,
      alertController: SnackbarUtils,
    });

    if (response) {
      setIsUploading(false);
      setShowImageUploadToTrainingSetModal(false);
    }
  };

  return (
    <Card style={{ maxWidth: isXpolImage ? xpolWidth : width }}>
      <CardHeader
        title={scores?.type?.name || ''}
        subheader={scores?.skin_area?.name || ''}
      />
      {/* {imageUrl ? (
        <CardMedia
          className={classes.media}
          image={imageUrl}
          title="Scan image "
          onClick={() => {
            const newWindow = window.open(
              image,
              '_blank',
              'noopener,noreferrer',
            );

            if (newWindow) newWindow.opener = null;
          }}
        />
      ) : (
        <div className={classes.media} />
      )} */}

      {loaded ? (
        <a href={imageUrl} target="_blank" rel="noreferrer">
          <img
            style={{
              ...(isXpolImage ? xpolMedia : { width, height }),
            }}
            src={imageBase64 || imageUrl}
            alt={`Scan ID: ${scanId}`}
          />
        </a>
      ) : (
        <div
          style={{
            ...{
              width,
              height,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
            ...(isXpolImage ? xpolMedia : {}),
          }}
        >
          <CircularProgress color="secondary" />
          <img
            style={{ display: 'none' }}
            src={imageBase64 || imageUrl}
            alt={`Scan ID: ${scanId}`}
            onLoad={handleImageLoad}
          />
        </div>
      )}

      <Grid container>
        <Grid item xs={item.anomalies || item.anomalies_uv ? 6 : 12}>
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {`Scan ID: ${scanId}`}
            </Typography>
            {scores?.moisture && (
              <Typography variant="body2" color="textSecondary" component="p">
                Moisture: {`${scores.moisture} ( Raw: ${scores.moisture_raw} )`}
              </Typography>
            )}
            {scores?.lines && (
              <Typography variant="body2" color="textSecondary" component="p">
                Lines: {`${scores.lines} ( Raw: ${scores.lines_raw} )`}
              </Typography>
            )}
            {scores?.lines_fine && (
              <Typography variant="body2" color="textSecondary" component="p">
                Fine lines:{' '}
                {`${trimToTwoDecimals(scores.lines_fine)} ( Raw: ${
                  scores.lines_fine_raw
                } )`}
              </Typography>
            )}
            {scores?.lines_coarse && (
              <Typography variant="body2" color="textSecondary" component="p">
                Coarse lines:{' '}
                {`${trimToTwoDecimals(scores.lines_coarse)} ( Raw: ${
                  scores.lines_coarse_raw
                } )`}
              </Typography>
            )}
            {scores?.skin_tone_evenness && (
              <Typography variant="body2" color="textSecondary" component="p">
                Skin tone evenness:{' '}
                {`${scores.skin_tone_evenness} ( Raw: ${trimToTwoDecimals(
                  scores.skin_tone_evenness_raw,
                )} )`}
              </Typography>
            )}
            {scores?.pores && (
              <Typography variant="body2" color="textSecondary" component="p">
                Pores: {`${scores.pores} ( Raw: ${scores.pores_raw} )`}
              </Typography>
            )}
            {scores?.oil && (
              <Typography variant="body2" color="textSecondary" component="p">
                Oil:{' '}
                {`${scores.oil} ( Raw: ${trimToTwoDecimals(scores.oil_raw)} )`}
              </Typography>
            )}
            <Typography variant="body2" color="textSecondary" component="p">
              {`Skin area: ${getSkinAreaTitle(image?.skinArea)}`}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {`Type:  ${getSourceTypeTitle(image?.type)}`}
            </Typography>
            {showImageUploadToTrainingSetModal && (
              <SimpleModal
                open={showImageUploadToTrainingSetModal}
                handleClose={() => setShowImageUploadToTrainingSetModal(false)}
              >
                <div className={classes.modal}>
                  <Typography
                    variant="h2"
                    style={{ wordWrap: 'break-word', marginBottom: 10 }}
                  >
                    Upload image to training set
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ wordWrap: 'break-word', marginBottom: 10 }}
                  >
                    {getFileNameFromS3Url(item.image?.url)}
                  </Typography>

                  <Typography
                    variant="body2"
                    style={{ wordWrap: 'break-word' }}
                  >
                    {hasMlPermissions && (
                      <AnomalyScores
                        scores={anomalyScores || uvScores}
                        showSelection
                        onSelectionChanged={(selection) =>
                          setUploadPath(selection)
                        }
                      />
                    )}
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 20,
                      }}
                    >
                      This image will be uploaded to the '
                      <strong>{uploadPath}</strong>' training set in the S3
                      bucket at:
                    </div>
                    <strong>
                      {`s3://fitskin-ml/training/${
                        isXpolImage ? 'dataset_anomalies' : 'dataset_uv'
                      }/`}
                      {uploadPath}
                    </strong>
                    <div
                      style={{
                        marginTop: 20,
                      }}
                    >
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        disabled={isUploading}
                        onClick={() =>
                          uploadImageToMlTrainingDataSet(image.url, uploadPath)
                        }
                      >
                        Upload
                      </Button>
                      <Button
                        type="button"
                        // variant="contained"
                        color="primary"
                        onClick={() =>
                          setShowImageUploadToTrainingSetModal(false)
                        }
                      >
                        Cancel
                      </Button>
                    </div>
                  </Typography>
                </div>
              </SimpleModal>
            )}
            {canUploadImageToMLTrainingSet &&
              (anomalyScores.length > 0 || uvScores.length > 0) && (
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => setShowImageUploadToTrainingSetModal(true)}
                  >
                    Add To Training Set...
                  </Button>
                </div>
              )}
          </CardContent>
        </Grid>

        {hasMlPermissions && (
          <>
            {item.anomalies && (
              <Grid item xs={6}>
                <CardContent style={{ overflow: 'auto', maxHeight: 250 }}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Anomaly data
                    {item.anomalies_version
                      ? ` (v${item.anomalies_version})`
                      : ''}
                    :
                  </Typography>
                  <AnomalyScores scores={anomalyScores} />
                </CardContent>
              </Grid>
            )}

            {item.anomalies_uv && (
              <Grid item xs={6}>
                <CardContent style={{ overflow: 'auto', maxHeight: 250 }}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Anomaly UV data
                    {item.anomalies_uv_version
                      ? ` (v${item.anomalies_uv_version})`
                      : ''}
                    :
                  </Typography>
                  <AnomalyScores scores={uvScores} />
                </CardContent>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Card>
  );
};
