import React, { useState, useEffect } from 'react';

// Placeholder image import
import placeholderImg from './no-image.png'; // Adjust path as needed
import { getSignedFileUrl } from 'api';

const ImageComponent = ({ image, signUrlUpfront = true }) => {
  const [imageUrl, setImageUrl] = useState(placeholderImg);

  useEffect(() => {
    const fetchImageUrl = async () => {
      try {
        const signedUrl = await getSignedFileUrl(
          image.region,
          image.bucket_name,
          image.image_path,
        );
        setImageUrl(signedUrl);
      } catch (error) {
        console.error('Error fetching signed image URL:', error);
        // Optionally, keep the placeholder if there's an error
        setImageUrl(placeholderImg);
      }
    };
    if (signUrlUpfront) fetchImageUrl();
  }, [signUrlUpfront]);

  const getImageThumbnailUrlByClient = () => {
    let thumbnailsBucketName = 'fitskin-images-thumbnails';
    let subdir = image.client;

    switch (subdir) {
      case 'wba':
        thumbnailsBucketName = 'fitskin-images-thumbnails-eu';
        break;
      default:
        break;
    }

    return `https://${thumbnailsBucketName}.s3.${image.region}.amazonaws.com/${subdir}/${image.image_path}`;
  };

  return (
    <img
      style={{
        margin: 'auto',
        display: 'block',
        width: 250,
        height: 250,
        // objectFit: 'contain',
        cursor: 'pointer',
      }}
      // src={imageUrl}
      src={getImageThumbnailUrlByClient()}
      onClick={() => {
        if (signUrlUpfront) {
          window.open(imageUrl, '_blank');
        } else {
          getSignedFileUrl(
            image.region,
            image.bucket_name,
            image.image_path,
          ).then((signedUrl) => {
            window.open(signedUrl, '_blank');
          });
        }
      }}
      alt="Displayed"
      onError={() => setImageUrl(placeholderImg)}
    />
  );
};

export default ImageComponent;
