import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { getClients } from './clients';
import { getMonths, getReportMode, getYears } from './months';
import { Form, Input } from 'components/Controls';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '10px',
  },
  selectInput: {
    marginTop: 16,
    marginBottom: 8,
    marginLeft: 10,
    marginRight: 10,
    width: '500px',
  },
  Input: {
    marginTop: 16,
    marginBottom: 8,
    marginLeft: 10,
    marginRight: 10,
    // set height
    width: '500px',
    zIndex: 0,
  },
  labelAction: {
    marginLeft: '10px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export const Filter = ({
  client,
  location,
  filter,
  reset: onReset,
  defaultMonth,
  defaultYear,
  reportType,
  isLoading,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      client: client
        ? {
            value: client,
            label: getClients().find((item) => item.ID === client)?.NAME,
          }
        : null,
      year: defaultYear
        ? { value: defaultYear, label: defaultYear.toString() }
        : null,
      month: defaultMonth
        ? {
            value: defaultMonth,
            label: getMonths().find((item) => item.ID === defaultMonth)?.NAME,
          }
        : null,
      reportMode: reportType
        ? {
            value: reportType,
            label: getReportMode().find((item) => item.ID === reportType)?.NAME,
          }
        : null,
      item: '',
    },
  });

  const clientsList = () =>
    getClients().map((item) => ({
      value: item.ID,
      label: item.NAME,
    }));

  const onSearch = handleSubmit((data) => {
    console.log('Submitted Data:', data);

    filter({
      client: data.client?.value,
      year: data.year?.value,
      month: data.month?.value,
      reportMode: data.reportMode?.value,
      item: data.item,
    });
  });

  const onClear = () => {
    console.log('Clearing Filters');
    reset({
      client: null,
      year: null,
      month: null,
      reportMode: null,
      item: null,
    });

    onReset();
  };

  return (
    <>
      <div className={classes.root}>
        <Form>
          <div className={classes.filtersContainer}>
            {/* Filter 1: Select Client */}
            <div className={classes.filterItem}>
              <Controller
                name="client"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={clientsList()}
                    isDisabled={isLoading}
                    className={classes.selectInput}
                  />
                )}
              />
            </div>

            <div className={classes.filterItem}>
              <Controller
                name="year"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={getYears().map((item) => ({
                      value: item.ID,
                      label: item.NAME,
                    }))}
                    isDisabled={isLoading}
                    className={classes.selectInput}
                  />
                )}
              />
            </div>
            <div className={classes.filterItem}>
              <Controller
                name="month"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={getMonths().map((item) => ({
                      value: item.ID,
                      label: item.NAME,
                    }))}
                    isDisabled={isLoading}
                    className={classes.selectInput}
                  />
                )}
              />
            </div>
            <div className={classes.filterItem}>
              <Controller
                name="reportMode"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={getReportMode().map((item) => ({
                      value: item.ID,
                      label: item.NAME,
                    }))}
                    isDisabled={isLoading}
                    className={classes.selectInput}
                  />
                )}
              />
            </div>

            <div className={classes.filterItem}>
              <Controller
                name="item"
                control={control}
                render={({ field }) => (
                  // Write txt input here
                  <Input
                    {...field}
                    name="item"
                    margin="dense"
                    type="text"
                    label="Scanner or Store"
                    className={classes.Input}
                    disabled={isLoading}
                    //error={!!errors.email}
                    //helperText={errors?.email?.message}
                  />
                )}
              />
            </div>
            <Grid
              container
              item
              md={12}
              lg={12}
              xl={12}
              direction="row"
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Grid
                container
                item
                xs={6}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    type="submit"
                    width="170px"
                    variant="contained"
                    color="primary"
                    onClick={onSearch}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Searching...' : 'Search'}
                  </Button>
                </Grid>
                <Grid item>
                  <Typography
                    className={classes.labelAction}
                    style={{ marginLeft: 16 }}
                    onClick={onClear}
                  >
                    Clear
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Form>
      </div>
    </>
  );
};
