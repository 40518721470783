import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, Typography } from '@material-ui/core';
import queryString from 'query-string';

import { CircularLoader } from 'components/Controls';

import { makeStyles } from '@material-ui/core/styles';
import { deleteImageFromDataSet, getDataSetById } from 'api';
import ImageComponent from 'components/image';
// theme & styling
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  pageTitle: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    fontSize: 24,
    fontWeight: 'bold',
  },
  tagButton: {
    margin: theme.spacing(1),
  },
  displayPanel: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap', // Allow items to wrap to the next line
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
  },
  //   imageContainer: {
  //     flex: '1 1 33%', // Each item should take up one-third of the row
  //     padding: theme.spacing(1),
  //     boxSizing: 'border-box', // Include padding and border in the element's total width
  //   },
  imageContainer: {
    flex: '1 1 33%', // Each item should take up one-third of the row
    display: 'flex', // Use flexbox for the container
    flexDirection: 'column', // Stack children vertically
    alignItems: 'center', // Center children horizontally
    padding: theme.spacing(1),
    boxSizing: 'border-box', // Include padding and border in the element's total width
  },
  deleteButton: {
    marginTop: theme.spacing(1), // Space between the image and the button
  },
  paperLeft: {
    paddingLeft: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const DataSetViewScreen = ({ location }) => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const [data, setData] = useState();
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [currentImageToDelete, setCurrentImageToDelete] = useState(null);
  const [refresher, setRefresher] = useState(0);

  useEffect(() => {
    if (data?.length > 0) {
      const firstCategory = data[0];
      setSelectedImages(firstCategory.images);
      setSelectedCategory(firstCategory.categoryName);
    }
  }, [data]);

  const handleTagClick = (category) => {
    setSelectedImages(category.images);
    setSelectedCategory(category.categoryName);
  };

  const extractDatasetId = (pathname) => {
    const regex = /\/datasets\/([a-f\d]{24})/i;
    const match = pathname.match(regex);
    return match ? match[1] : null;
  };

  const getDataSetName = (qsParams) => {
    const { name } = qsParams;
    return name ? decodeURIComponent(name) : '';
  };

  useEffect(() => {
    (async function fetchDataSetImages() {
      try {
        setIsLoading(true);
        var id = extractDatasetId(location.pathname);
        const dataSetContainers = await getDataSetById(id);
        setData(dataSetContainers);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [location.pathname, refresher]);

  const deleteImageFromTrainingSet = async (imageKey) => {
    // Call API to delete image
    const deleteResponse = await deleteImageFromDataSet(
      extractDatasetId(location.pathname),
      selectedCategory,
      imageKey,
    );

    if (!deleteResponse) {
      return;
    }

    setSelectedImages(
      selectedImages.filter(
        (image) => image.imageKey !== currentImageToDelete.imageKey,
      ),
    );
    setRefresher((prev) => prev + 1);
  };

  return (
    <div>
      {showDeleteConfirmation && (
        <Modal
          open={showDeleteConfirmation}
          onClose={() => setShowDeleteConfirmation(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box className={classes.modal}>
            <Typography id="simple-modal-title" variant="h6">
              Confirm Deletion
            </Typography>
            <Typography id="simple-modal-description">
              Are you sure you want to delete this image?
            </Typography>
            <Button
              onClick={async () => {
                setShowDeleteConfirmation(false);
                await deleteImageFromTrainingSet(currentImageToDelete.imageKey);
                // Refresh the data or remove the image from state as needed
              }}
              color="primary"
            >
              Yes
            </Button>
            <Button
              onClick={() => setShowDeleteConfirmation(false)}
              color="secondary"
            >
              No
            </Button>
          </Box>
        </Modal>
      )}
      <Typography className={classes.pageTitle} align="left">
        {getDataSetName(queryString.parse(location.search)) || 'Data Set'}
      </Typography>
      <Typography className={classes.paperLeft} align="left">
        {extractDatasetId(location.pathname)}
      </Typography>
      <div className={classes.paper}>
        {isLoading && <CircularLoader />}

        {data?.map((item, index) => (
          <Button
            key={index}
            variant={
              item.categoryName === selectedCategory ? 'contained' : 'outlined'
            }
            color="primary"
            className={classes.tagButton}
            onClick={() => handleTagClick(item)}
          >
            {item.categoryName}
          </Button>
        ))}
        <div className={classes.displayPanel}>
          {selectedImages?.map((image, index) => (
            <div className={classes.imageContainer} key={index}>
              <ImageComponent
                image={{
                  region: image.region,
                  bucket_name: image.bucketName,
                  image_path: image.originalImageKey,
                  client: image.client,
                }}
                signUrlUpfront={false}
              />
              <Button
                className={classes.deleteButton}
                color="secondary"
                onClick={() => {
                  setShowDeleteConfirmation(true);
                  setCurrentImageToDelete(image);
                }}
              >
                Delete
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
