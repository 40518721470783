import axios from 'axios';
import { useRef, useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const MembershipRoutes = {
  getUser: (userId) => `/users/${userId}`,
  getAvailableRoles: '/users/roles',
  login: '/auth/login',
  logout: '/auth/logout',
};
export const StatisticsRoutes = {
  getSourceTypes: '/statistics/source-types',
  getScans: '/statistics/scans',
  getScanners: '/statistics/scanners',
  getStoreScanners: '/statistics/stores/scanners',
  getStores: '/statistics/stores',
  getAppVersionScanners: '/statistics/app-version/scanners',
  getAppVersions: '/statistics/app-versions',
  getUploadsDetails: '/statistics/images/structure',
};
export const QatRoutes = {
  qat: '/qat',
  qatItem: (id) => `/qat/${id}`,
};

export const ApiRoutes = {
  ...StatisticsRoutes,
  ...MembershipRoutes,
  ...QatRoutes,

  // anomaliesJobs: '/jobs/xpol',
  // anomaliesScores: '/jobs/xpol/scores-by-date',
  // anomaliesTopScores: '/jobs/xpol/top-scores',
  // uvJobs: '/jobs/uv',
  // uvScores: '/jobs/uv/scores-by-date',
  // uvTopScores: '/jobs/uv/top-scores',
  getSignedUrl: '/utils/signed-file-url',
  UploadImageToTrainingSetRequest: '/data-sets/upload-image-to-training-set',
  dataSets: '/data-sets',
  getAnomaliesResults: '/anomalies/scanners',
  getDailyAnomalies: '/anomalies/daily',
  scannerAnomalies: '/anomalies/scanner',
  dataSetImages: '/data-sets/images',
  feedback: '/feedback',
};

const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`,
});

API.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const AxiosInterceptor = ({ children }) => {
  const history = useHistory();
  const location = useLocation();

  useLayoutEffect(() => {
    const resInterceptor = (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response.data;
    };

    const errInterceptor = (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response.status === 401 && location.pathname !== '/sign-in') {
        const redirect = `?redirect=${encodeURIComponent(
          location.pathname + location.search,
        )}`;

        window.location.href = '/sign-in' + redirect;

        // history.push({
        //   pathname: '/sign-in',
        //   search: redirect,
        // });
      }

      return Promise.reject(error.response.data);
    };

    const interceptor = API.interceptors.response.use(
      resInterceptor,
      errInterceptor,
    );

    return () => API.interceptors.response.eject(interceptor);
  }, [history]);
  return children;
};

export default API;
export { AxiosInterceptor };
