import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { Controller } from 'react-hook-form';

export const SelectInput = ({
  name,
  label,
  control,
  defaultValue,
  children,
  SelectProps,
  ...props
}) => {
  const labelId = `${name}-label`;

  return (
    <FormControl variant="outlined" size="small" fullWidth {...props}>
      <InputLabel htmlFor={labelId} id={labelId}>
        {label}
      </InputLabel>
      <Select
        labelId={labelId}
        label={label}
        defaultValue={defaultValue}
        {...SelectProps}
      >
        {children}
      </Select>
    </FormControl>
  );
};
