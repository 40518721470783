import React from 'react';
import { Config } from 'configs';
import { Button } from '@material-ui/core';
import { SnackbarUtils } from 'utils/snackbarUtils';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Input, MainContainer } from 'components/Controls';
import {
  CssBaseline,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
// theme & styling
const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minWidth: 500,
    marginLeft: theme.spacing(8),
  },
  filterItem: {
    [theme.breakpoints.up('xs')]: {
      margin: '0px 12px !important',
    },
    [theme.breakpoints.up('md')]: {
      margin: '0px 24px !important',
    },

    backgroundColor: 'white',
  },
  list: {
    [theme.breakpoints.up('xs')]: {
      margin: '10px 12px !important',
    },
    [theme.breakpoints.up('md')]: {
      margin: '20px 24px !important',
    },
  },
  labelAction: {
    marginLeft: '10px',
    marginTop: '20px',
    color: `${theme.palette.primary.main}`,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  // paper: {
  //   position: 'absolute',
  //   width: 400,
  //   boxShadow: theme.shadows[5],
  // },
  infoBlock: {
    //width: 250,
  },

  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    //boxShadow: theme.shadows[5],
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    alignItems: 'center',
  },
  input: {
    width: '90%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const schema = yup.object().shape({
  name: yup
    .string('Please enter name of Data Set')
    .max(255)
    .required('Data Set field is required')
    .default(`New Data Set ${new Date().toISOString().split('T')[0]}`),
  model_type: yup
    .string('Please select model type')
    .max(255)
    .required('Model type field is required')
    .default(''),
});

export const DataSetAddNewForm = ({ onSave, onCancel }) => {
  const classes = styles();
  const [selectedModelType, setSelectedModelType] = React.useState('');

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: schema.default(),
    resolver: yupResolver(schema),
  });

  const onFormSubmit = async (data) => {
    if (onSave) {
      onSave(data);
    }
  };

  const onFormError = (e) => {
    Config.SHOW_LOGS && console.log(e);
    SnackbarUtils.info('Error occured during form submition event.', false);
    // setIsLoading(false);
  };

  const onSelectModelType = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedModelType(value);
    setValue('model_type', value, { shouldDirty: true });
  };

  return (
    <MainContainer>
      <CssBaseline />
      <div className={classes.root}>
        <Form
          className={classes.form}
          onSubmit={handleSubmit(onFormSubmit, onFormError)}
        >
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label="name"
                onBlur={onBlur}
                onChange={onChange}
                defaultValue={value}
                className={classes.input}
                error={!!errors.name}
                helperText={errors?.name?.message}
                onFocus={(e) => e.target.select()}
              />
            )}
            name="name"
          />

          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <FormControl fullWidth>
                <InputLabel
                  //style={{ marginLeft: 10, marginBottom: 200 }}
                  id="model-type-label"
                  style={{ marginLeft: 10, marginTop: -5 }}
                >
                  Model Type
                </InputLabel>
                <Select
                  labelId="model-type-label"
                  label="Model type"
                  id="model-type-checkbox"
                  placeholder="Select model type"
                  value={selectedModelType}
                  onChange={onSelectModelType}
                  input={<OutlinedInput label="Tag" />}
                  className={classes.input}
                  //   renderValue={(selected) =>
                  //     selected.map((s) => USER_ROLES[s.lastName]).join(', ')
                  //   }
                  // MenuProps={MenuProps}
                >
                  {[
                    { id: 'anomalies', label: 'Anomalies' },
                    { id: 'uv', label: 'UV' },
                  ].map((mt) => (
                    <MenuItem key={mt.id} value={mt.id}>
                      {/* <Checkbox checked={value.indexOf(mt.label) > -1} /> */}
                      <ListItemText primary={mt.label} />
                    </MenuItem>
                  ))}
                </Select>
                {/* <FormHelperText>Required</FormHelperText> */}
              </FormControl>
            )}
            name="model_type"
          />

          <div style={{ alignItems: 'flex-end', flex: 1, width: '100%' }}>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
            <Button
              color="primary"
              className={classes.submit}
              onClick={() => {
                if (onCancel) onCancel();
              }}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </MainContainer>
  );
};
