import React from 'react';
import { Grid } from '@material-ui/core';
import { LoginForm } from 'components/Membership/LoginForm';

export const Login = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Grid container justifyContent="center">
        <LoginForm />
      </Grid>
    </Grid>
  </Grid>
);
