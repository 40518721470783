import React, { useContext, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Context as AuthContext } from 'contexts/AuthContext';
import { Form, Input, MainContainer } from 'components/Controls';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Logo } from 'components/Navigation/Logo';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const schema = yup.object().shape({
  email: yup
    .string('Please enter your email')
    .email('Seems your email format is invalid')
    .required('Email field is required'),
  password: yup
    .string('Please enter your password')
    .required('Password field is required'),
});

const Copyright = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <Link color="inherit" href="https://fitskin.co/">
      FitSkin
    </Link>{' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>
);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const LoginForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { signin } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const {
    register, //
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const submitHandler = async (data) => {
    setIsLoading(true);

    const redirect = queryString.parse(location.search).redirect || '/';
    const response = await signin(data, redirect);

    if (!response) {
      reset({ ...data, password: null });
      enqueueSnackbar(t('login_fail'), { variant: 'error' });
    }

    setIsLoading(false);
  };

  return (
    <MainContainer>
      <CssBaseline />
      <div className={classes.paper}>
        <Logo />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Form className={classes.form} onSubmit={handleSubmit(submitHandler)}>
          <Input
            {...register('email', { required: true })}
            name="email"
            type="email"
            label="Email"
            error={!!errors.email}
            helperText={errors?.email?.message}
          />
          <Input
            {...register('password', { required: true })}
            name="password"
            type="password"
            label="Password"
            error={!!errors.password}
            helperText={errors?.password?.message}
          />

          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isLoading}
          >
            {isLoading && (
              <CircularProgress
                size={16}
                color="inherit"
                style={{ marginRight: 4 }}
              />
            )}
            {isLoading ? 'Signing In...' : 'Sign In'}
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/sign-up" variant="body2">
                Dont have an account? Sign Up
              </Link>
            </Grid>
          </Grid> */}
        </Form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </MainContainer>
  );
};
