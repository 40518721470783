import React, { useEffect, useState } from 'react';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CircularLoader } from 'components/Controls';
import { getDataSets, getScores } from 'api';
import queryString from 'query-string';
import ImageComponent from 'components/image';
import PortalLink from 'components/portalLink';
import AddToTrainingModal from './AddToTrainingModal';
import AnomalyItem from './AnomalyItem';
import AnomalyUvItem from './AnomalyUvItem';

export const AnomaliesByDateScreen = ({ location }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      //marginLeft: theme.spacing(2),
      border: '1px solid rgba(0, 0, 0, 0.12)',
      padding: '10px',
      minWidth: 400,
      maxWidth: 400,
    },
    header: {
      backgroundColor: 'lightgray',
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paperLeft: {
      padding: theme.spacing(1),
      textAlign: 'left',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
    },
    paperRight: {
      padding: theme.spacing(1),
      textAlign: 'right',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
      // textDecoration: 'underline',
    },
    pageTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 24,
      fontWeight: 'bold',
    },
    categoryTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 18,
      fontWeight: 'bold',
    },
    cell: {
      width: '100%',
    },
    thresholdCell: {
      ...theme.legendCell,
      backgroundColor: '#e2f1f8',
    },
    thresholdCellAlt: {
      ...theme.legendCell,
      backgroundColor: '#c1d5e0',
    },
    selectInput: {
      //zIndex: 100,
      marginTop: 16,
      marginBottom: 8,
      marginLeft: 10,
      marginRight: 10,
    },
    paperBold: {
      width: '110px',
      padding: theme.spacing(1),
      fontSize: 14,
      backgroundColor: '#e0ffee',
      fontWeight: 'bold',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    csvLink: {
      marginTop: theme.spacing(2),
    },
    modal: {
      padding: 60,
      position: 'absolute',
      width: 500,
      height: 700,
      display: 'block',
      boxShadow: theme.shadows[5],
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
    },
  }));
  const classes = useStyles();

  const [scoresData, setScoresData] = useState([]);
  const [dataSets, setDataSets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImageItem, setSelectedImageItem] = useState(null);
  const [
    showImageUploadToTrainingSetModal,
    setShowImageUploadToTrainingSetModal,
  ] = useState(false);

  const hasData = scoresData != null && scoresData.length > 0;

  const qsParams = queryString.parse(location.search);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      if (qsParams) {
        const data = await getScores(qsParams);
        setScoresData(data || []);
      }

      setIsLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    (async function fetchDataSets() {
      try {
        const ds = await getDataSets(qsParams.type);
        setDataSets(ds);
      } catch (e) {
        console.log(e);
      } finally {
      }
    })();
  }, []);

  const renderGrid = (type) => {
    if (!hasData) {
      return null;
    }

    return (
      <Grid item spacing={0}>
        <div>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <Typography className={classes.categoryTitle} align="left">
              20 anomalies with max score of {qsParams.propertyName}
            </Typography>
          </div>
          <Grid item spacing={0}>
            {scoresData.map((item, index) => {
              return (
                <Paper className={classes.paper} key={index}>
                  <Grid container spacing={0}>
                    <Grid item lg={3} xs={3} sm={3}>
                      <Typography className={classes.paperLeft}>
                        <Typography className={classes.paperLeft}>
                          {type === 'uv' && (
                            <AnomalyUvItem
                              item={item}
                              highlightedProperty={qsParams.propertyName}
                            />
                          )}
                          {type === 'anomalies' && (
                            <AnomalyItem
                              item={item}
                              highlightedProperty={qsParams.propertyName}
                            />
                          )}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.paperRight}>
                        <ImageComponent image={item} />
                        <Button
                          type="buttton"
                          variant="contained"
                          color="primary"
                          style={{ marginTop: 10, width: '100%' }}
                          onClick={() => {
                            setSelectedImageItem(item);
                            setShowImageUploadToTrainingSetModal(true);
                          }}
                        >
                          Add To Training Set...
                        </Button>
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              );
            })}
          </Grid>
        </div>
      </Grid>
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <div>
        <Typography className={classes.pageTitle} align="left">
          Client: {qsParams.client}
        </Typography>
        <Typography className={classes.pageTitle} align="left">
          Measure: {qsParams.propertyName}
        </Typography>
        <Typography className={classes.pageTitle} align="left">
          Date: {qsParams.date}
        </Typography>
      </div>
      <div style={{ marginTop: 30 }}></div>
      <div style={{ marginTop: 10 }}>
        {isLoading && (
          <CircularLoader
            doNotCenter
            customStyle={{
              flexGrow: 1,
              width: '100vw',
            }}
          />
        )}
      </div>
      {!isLoading && renderGrid(qsParams.type)}
      {showImageUploadToTrainingSetModal && selectedImageItem != null && (
        <AddToTrainingModal
          isOpen={showImageUploadToTrainingSetModal}
          onClose={() => {
            setSelectedImageItem(null);
            setShowImageUploadToTrainingSetModal(false);
          }}
          client={qsParams.client}
          dataSets={dataSets}
          selectedImageItem={selectedImageItem}
          anomalies={selectedImageItem}
        />
      )}
    </div>
  );
};
