import React, { useCallback, useContext, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { Context as AuthContext } from 'contexts/AuthContext';
import { Context as PreferencesContext } from 'contexts/PreferencesContext';
import { CircularLoader } from 'components/Controls/CircularLoader';
import { LayoutRoute } from './LayoutRoute';

export const Router = ({ routes }) => {
  const { initService, state } = useContext(AuthContext);
  const { initService: initPreferences, state: preferences } = useContext(PreferencesContext);
  const { initialized } = state;
  const { preferencesInitialized } = preferences;

  const Init = useCallback(async () => {
    await initService();
    await initPreferences();
  }, [initService, initPreferences]);

  useEffect(() => {
    Init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!initialized && !preferencesInitialized) return <CircularLoader />;

  return (
    <Switch>
      {Object.entries(routes).map(([_, routeConfig], index) => (
        // Wont change during run time, so use array index as key
        // eslint-disable-next-line react/no-array-index-key
        <LayoutRoute
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          {...routeConfig}
        />
      ))}
    </Switch>
  );
};
