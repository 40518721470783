import React, { useEffect, useMemo, useState } from 'react';
import { getAnomaliesResults } from 'api';
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import ScannerList from './Scanner';
import { makeStyles, Typography } from '@material-ui/core';

const AnomalousScannersByDay = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      //marginLeft: theme.spacing(2),
      border: '1px solid rgba(0, 0, 0, 0.12)',
      padding: '10px',
      minWidth: 400,
      maxWidth: 400,
    },
    pageTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 24,
      fontWeight: 'bold',
    },
    categoryTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 18,
      fontWeight: 'bold',
    },
    selectInput: {
      //zIndex: 100,
      marginTop: 16,
      marginBottom: 8,
      marginLeft: 10,
      marginRight: 10,
    },
    algorithmExplanation: {
      //zIndex: 100,
      marginTop: 16,
      marginBottom: 16,
    },
  }));
  const classes = useStyles();

  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const [jsonResponse, setJsonResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const clientName = searchParams.get('name');
    const model = searchParams.get('model');
    const date = searchParams.get('date');

    if (clientName && model) {
      fetchResults(clientName, model, date);
    }
  }, [searchParams]);

  const fetchResults = async (clientName, model, date) => {
    try {
      setIsLoading(true);
      const response = await getAnomaliesResults(
        clientName,
        model,
        date,
        'daily',
        'count',
      );
      setJsonResponse(response);
    } catch (error) {
      console.error('Error fetching anomaly results:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Typography className={classes.pageTitle} align="left">
        Anomalous Scanners By Day
      </Typography>

      <Typography className={classes.categoryTitle} align="left">
        <strong>Model:</strong> {searchParams.get('model')}
      </Typography>
      <Typography className={classes.categoryTitle} align="left">
        <strong>Client:</strong> {searchParams.get('name')}
      </Typography>
      <Typography className={classes.categoryTitle} align="left">
        <strong>Day:</strong> {searchParams.get('date')}
      </Typography>

      <div>
        {isLoading ? (
          <CircularProgress />
        ) : jsonResponse?.length > 0 ? (
          <>
            <ScannerList
              algorithm={'count'}
              model={searchParams.get('model')}
              scanners={jsonResponse}
              isLoading={isLoading}
              onScannerClick={(id, propertyNameToSort) => {
                window.open(
                  `/scanner-anomalies?client=${searchParams.get(
                    'name',
                  )}&scanner=${id}&model=${searchParams.get(
                    'model',
                  )}&date_range=${searchParams.get(
                    'date',
                  )}&report_type=daily&property_name=${propertyNameToSort}`,
                  '_blank',
                );
              }}
            />
          </>
        ) : (
          <div>No data found</div>
        )}
      </div>
    </div>
  );
};

export default AnomalousScannersByDay;
