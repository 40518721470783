import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './en/translation.json';
import translationIT from './it/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  it: {
    translation: translationIT,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },

  fallbackLng: 'en',

  react: {
    wait: false,
    omitBoundRerender: false,
  },
});

// eslint-disable-next-line import/no-default-export
export default i18n;
