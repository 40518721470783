import React from 'react';
import { Typography } from '@mui/material';
import PortalLink from 'components/portalLink';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paperLeft: {
    padding: theme.spacing(1),
    textAlign: 'left',
    paddingLeft: 20,
    color: theme.palette.text.secondary,
  },
  paperRight: {
    padding: theme.spacing(1),
    textAlign: 'right',
    paddingLeft: 20,
    color: theme.palette.text.secondary,
    // textDecoration: 'underline',
  },
}));

const AnomalyUvItem = ({ item, highlightedProperty }) => {
  const classes = useStyles();

  const underlineStyle = {
    textDecoration: 'underline',
  };

  const getStyle = (propertyName) =>
    highlightedProperty === propertyName ? underlineStyle : null;

  return (
    <>
      <Typography className={classes.paperLeft}>
        <PortalLink image={item} />
      </Typography>
      <Typography className={classes.paperLeft}>
        Model: {item.model_version}
      </Typography>
      <Typography className={classes.paperLeft} style={getStyle('valid')}>
        Valid: {(parseFloat(item.valid) * 100).toFixed(2)}%
      </Typography>
      <Typography className={classes.paperLeft} style={getStyle('black')}>
        Black: {(parseFloat(item.black) * 100).toFixed(2)}%
      </Typography>
      <Typography className={classes.paperLeft} style={getStyle('lift_black')}>
        Lift black: {(parseFloat(item.lift_black) * 100).toFixed(2)}%
      </Typography>
      <Typography className={classes.paperLeft} style={getStyle('lift_off')}>
        Lift off: {(parseFloat(item.lift_off) * 100).toFixed(2)}%
      </Typography>
      {/* <Typography className={classes.paperLeft} style={getStyle('missing')}>
       Missing: {(parseFloat(item.missing) * 100).toFixed(2)}%
      </Typography> */}
    </>
  );
};

export default AnomalyUvItem;
