import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CircularLoader } from 'components/Controls';
import { validateScanners, postQatResults, getQatValidationResults } from 'api';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useHistory } from 'react-router-dom';

export const QatValidationsScreen = ({ location }) => {
  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      //marginLeft: theme.spacing(2),
      border: '1px solid rgba(0, 0, 0, 0.12)',
      padding: '10px',
    },
    pageTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 24,
      fontWeight: 'bold',
    },
    warning: {
      color: 'red',
      padding: theme.spacing(1),
      fontSize: 16,
      fontWeight: 'bold',
    },
  }));
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState();
  const [validationResults, setValidationResults] = useState();
  const [csvFormatIsSemiColon, setCsvFormatIsSemiColon] = useState(true);

  useEffect(() => {
    (async function fetchQatValidationResults() {
      try {
        setIsLoading(true);
        const results = await getQatValidationResults();
        console.log('results', results);
        setValidationResults(results);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsUploading(true);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);

    const reader = new FileReader();

    reader.onload = async (e) => {
      const text = e.target.result;
      let scanners = [];
      const scannerLines = text
        .split('\n')
        .map((line) => line.split(csvFormatIsSemiColon ? ';' : ','));

      if (scannerLines) {
        scannerLines.forEach((line) => {
          scanners.push(...line.filter((item) => item.length > 5));
        });
      }
      const response = await validateScanners(scanners);
      console.log('cia po validavimo resultato', response);

      const resultInsertResponse = await postQatResults(
        scanners,
        response,
        file.name,
      );
      console.log(resultInsertResponse);

      if (resultInsertResponse && resultInsertResponse.acknowledged === true) {
        history.push(`/qat/details?id=${resultInsertResponse.insertedId}`);
      }

      setIsUploading(false);
    };

    reader.readAsText(file);
  };

  return (
    <div className={classes.root}>
      <div>
        <Typography className={classes.pageTitle} align="left">
          QAT - Scanners Validation
        </Typography>
      </div>
      <div>
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" item xs={12}>
            <Grid item alignItems="center" xs={3}>
              <FormGroup className={classes.selectInput}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={csvFormatIsSemiColon}
                      onChange={() =>
                        setCsvFormatIsSemiColon(!csvFormatIsSemiColon)
                      }
                    />
                  }
                  label={
                    csvFormatIsSemiColon
                      ? 'Semi-colon separated'
                      : 'Comma separated'
                  }
                />
              </FormGroup>
              <Typography className={classes.warning} align="left">
                CSV file should be{' '}
                {csvFormatIsSemiColon ? 'semi-colon' : 'comma'} separated.
              </Typography>
              <input type="file" onChange={handleChange} />
              {file && <button type="submit">Upload</button>}
              {isUploading && (
                <div>
                  <span>
                    Uploading CSV file. We will redirect you to the details page
                    when it will be finished
                  </span>
                  <CircularProgress />
                </div>
              )}
            </Grid>
          </Grid>
        </form>
      </div>
      {!isLoading && (
        <div style={{ width: '50%', marginTop: 30 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>File</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {validationResults?.map((row) => (
                  <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {`${new Date(row.date).toLocaleTimeString()} ${new Date(
                        row.date,
                      ).toDateString()}`}
                    </TableCell>
                    <TableCell>{row.file}</TableCell>
                    <TableCell>
                      <a href={`/qat/details?id=${row._id}`}>View</a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      {isLoading && <CircularLoader />}
    </div>
  );
};
