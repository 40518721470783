import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Radio } from '@material-ui/core';

export const AnomalyScores = ({
  scores,
  showSelection = false,
  onSelectionChanged,
}) => {
  const [selectedScore, setSelectedScore] = useState(null);

  useEffect(() => {
    if (selectedScore == null && scores.length > 0) {
      const maxScoreItem = scores.reduce(
        (maxItem, currentItem) =>
          currentItem.score > maxItem.score ? currentItem : maxItem,
        scores[0],
      );
      setSelectedScore(maxScoreItem.name);
      if (onSelectionChanged) {
        onSelectionChanged(maxScoreItem.name);
      }
    }
  }, [selectedScore, scores, onSelectionChanged]);

  const handleSelectionChange = (event) => {
    setSelectedScore(event.target.value);
    if (onSelectionChanged) {
      onSelectionChanged(event.target.value);
    }
  };

  const items = scores.map((item, index) => (
    <Typography
      key={`${item.name}-anomaly`}
      variant="body2"
      color="textSecondary"
      component="p"
    >
      {showSelection && (
        <Radio
          key={selectedScore}
          checked={selectedScore === item.name}
          onChange={handleSelectionChange}
          value={item.name}
          name="score-radio-button"
          inputProps={{ 'aria-label': `Select ${item.name}` }}
        />
      )}
      {`${item.name}: ${!!item.score ? item.score.toFixed(4) : null}`}
    </Typography>
  ));

  return items;
};
