import React, { useContext, useEffect, useState } from 'react';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CircularLoader } from 'components/Controls';
import { getDistinctStores, getScannersUsageByStores } from 'api';
import { Form } from 'components/Controls';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { Context as AuthContext } from 'contexts/AuthContext';
import { Config } from 'configs';
import { getClients } from 'views/controls/clients';
import { getMonths, getYears } from 'views/controls/months';

export const StoresScreen = ({ location }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      border: '1px solid rgba(0, 0, 0, 0.12)',
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%', // Ensures the container takes full height if needed
    },
    pageTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 24,
      fontWeight: 'bold',
    },
    filtersContainer: {
      display: 'flex',
      flexDirection: 'column', // Arrange filters in a single column
      alignItems: 'flex-start',
      gap: theme.spacing(2), // Space between filter items
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    filterItem: {
      width: '25%', // Each filter takes up 25% of the screen width
      minWidth: '200px', // Ensures a minimum width for smaller screens
      padding: theme.spacing(1),
      boxSizing: 'border-box',
    },
    selectInput: {
      width: '100%', // Make Select input take full width of its container
    },
    header: {
      backgroundColor: 'lightgray',
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paperLeft: {
      padding: theme.spacing(1),
      textAlign: 'left',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
    },
    cell: {
      width: '100%',
    },
    paperBold: {
      width: '110px',
      padding: theme.spacing(1),
      fontSize: 14,
      backgroundColor: '#e0ffee',
      fontWeight: 'bold',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    csvLink: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  const transformSource = (sourceItems) => {
    const sourceItemsFiltered = sourceItems?.filter(
      (customer) => customer.ID !== -1,
    );

    return sourceItemsFiltered?.map((item) => {
      return {
        value: item.ID,
        label: item.NAME,
      };
    });
  };

  const currentMonth = new Date().getMonth() + 1;
  const currentMonthObject = transformSource(getMonths()).find(
    (m) => m.value === currentMonth,
  );

  const { state: authState } = useContext(AuthContext);
  const [customers] = useState(transformSource(getClients()) || []);
  const [selectedCustomer, setSelectedCustomer] = useState(
    authState.user?.role === Config.ROLES.CUSTOMER
      ? {
          value: authState.user.id,
          label: authState.user.customer,
        }
      : null,
  );

  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [years] = useState(transformSource(getYears()) || []);
  const [selectedYear, setSelectedYear] = useState({
    value: 2024,
    label: '2024',
  });
  const [months] = useState(transformSource(getMonths()) || []);
  const [selectedMonth, setSelectedMonth] = useState(
    currentMonthObject || null,
  );
  const [storeAndScannersPair, setStoreAndScannersPair] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isStoresIsLoading, setStoresIsLoading] = useState(false);

  const [csvResultData, setCsvResultData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setStoresIsLoading(true);

      if (selectedCustomer) {
        setCsvResultData(null);
        const storesResult = await getDistinctStores(selectedCustomer.value);

        const convertedStores = storesResult?.map((item) => {
          return {
            value: item,
            label: item,
          };
        });

        setStores(convertedStores || []);
      }

      setStoresIsLoading(false);
    };
    fetchData();
  }, [selectedCustomer]);

  const onSearch = async () => {
    setIsLoading(true);
    const customer = selectedCustomer?.value;
    const store = selectedStore?.value;
    const year = selectedYear?.value;
    const month = selectedMonth?.value;

    const scannersUsageByStoreResult = await getScannersUsageByStores(
      customer,
      year,
      month,
      store,
    );

    if (scannersUsageByStoreResult) {
      setStoreAndScannersPair(scannersUsageByStoreResult);

      setCsvResultData(
        scannersUsageByStoreResult?.map((item) => [
          item.store_id,
          item.totalScansInMonth,
          item.scannersLength,
          [...item.scanners],
        ]),
      );
    }
    setIsLoading(false);
  };

  const renderData = (pairs) => {
    let rows = [];
    if (pairs && pairs.length > 0) {
      pairs.forEach((pair) => {
        let outputCells = [];
        outputCells.push(pair.store_id);
        outputCells.push(pair.totalScansInMonth);
        outputCells.push(pair.scannersLength);
        outputCells.push(pair.scanners?.join(', '));
        rows.push(outputCells);
      });
    }

    return rows.map((row, index) => {
      return (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '140px 180px  140px 1000px',
            gridGap: 0,
            backgroundColor: 'transparent',
            textAlign: 'center',
            lineHeight: '20px',
            verticalAlign: 'middle',
          }}
        >
          {row.map((cell, cellIndex) => {
            return (
              <Grid item className={classes.cell}>
                <Paper
                  className={
                    cellIndex === 3 ? classes.paperLeft : classes.paper
                  }
                >
                  {cell}
                </Paper>
              </Grid>
            );
          })}
        </div>
      );
    });
  };

  const renderHeader = (dates) => {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '140px 180px  140px 1000px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <div>
          <Paper className={classes.header}>
            <span>Store</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Month scan sessions</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Scanners amount</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Scanners</span>
          </Paper>
        </div>
      </div>
    );
  };

  const renderGrid = () => {
    return (
      <Grid container spacing={0}>
        {renderHeader()}
        {renderData(storeAndScannersPair)}
      </Grid>
    );
  };

  const fullWidthGrid = () => {
    if (!storeAndScannersPair || storeAndScannersPair.length === 0) {
      return <div>No data</div>;
    }

    return (
      <div>
        <Grid className={classes.filterItem} item>
          {csvResultData && (
            <CSVLink
              filename={`${selectedCustomer.label} ${selectedMonth.label}-${selectedYear.label}.csv`}
              data={csvResultData}
            >
              <Typography className={classes.labelAction} align="left">
                Export {`${selectedMonth.label} of ${selectedYear.label}`} data
                to CSV
              </Typography>
            </CSVLink>
          )}
        </Grid>
        <Grid container spacing={0}>
          {renderGrid()}
        </Grid>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {/* Page Title */}
      <Typography className={classes.pageTitle} align="left">
        Scanners Usage by Stores
      </Typography>

      {/* Filters Section */}
      <div className={classes.root}>
        <Form>
          <div className={classes.filtersContainer}>
            {/* Filter 1: Select Client */}
            <div className={classes.filterItem}>
              <Select
                onChange={(selectedItem) => {
                  setSelectedCustomer(selectedItem);
                }}
                placeholder="Select client"
                className={classes.selectInput}
                options={customers}
              />
            </div>
            <div className={classes.filterItem}>
              <Select
                onChange={(selectedItem) => {
                  setSelectedYear(selectedItem);
                }}
                placeholder="Year"
                isDisabled={!selectedCustomer}
                value={selectedYear}
                options={years}
                className={classes.selectInput}
              />
            </div>
            <div className={classes.filterItem}>
              <Select
                onChange={(selectedItem) => {
                  setSelectedMonth(selectedItem);
                }}
                placeholder="Month"
                isDisabled={!selectedCustomer}
                value={selectedMonth}
                options={months}
                className={classes.selectInput}
              />
            </div>
            <div className={classes.filterItem}>
              <Select
                onChange={(selectedItem) => {
                  setSelectedStore(selectedItem);
                }}
                placeholder="Select store (optional)"
                isDisabled={isStoresIsLoading || !selectedCustomer}
                value={selectedStore}
                options={stores}
                className={classes.selectInput}
              />
            </div>
            <div className={classes.filterItem}>
              <Button
                type="button"
                width="170px"
                variant="contained"
                color="primary"
                disabled={!selectedCustomer || isLoading}
                onClick={onSearch}
              >
                Search
              </Button>
            </div>

            <Grid className={classes.csvLink} item>
              {/* {csvResultData && !isLoading && (
              <CSVLink
                filename={`${selectedAppVersion?.label}.csv`}
                data={csvResultData}
              >
                <Typography className={classes.labelAction} align="left">
                  Export unused scanners for {selectedAppVersion?.label} version
                  to CSV
                </Typography>
              </CSVLink>
            )} */}
            </Grid>
          </div>
        </Form>
      </div>
      <div style={{ marginTop: 30 }}></div>
      {!isLoading && fullWidthGrid()}
      {isLoading && <CircularLoader />}
    </div>
  );
};
