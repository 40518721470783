import API, { ApiRoutes } from 'components/API';

export const getAnomaliesJobs = async (client) =>
  await API.get(ApiRoutes.anomaliesJobs, {
    params: { client },
  });
export const getUvJobs = async (client) =>
  await API.get(ApiRoutes.uvJobs, {
    params: { client },
  });
export const getScores = async (params) => {
  if (params.type === 'anomalies') {
    return await API.get(ApiRoutes.anomaliesScores, {
      params: { ...params },
    });
  }
  return await API.get(ApiRoutes.uvScores, {
    params: { ...params },
  });
};
export const getTopScores = async (params) => {
  if (params.type === 'anomalies') {
    return await API.get(ApiRoutes.anomaliesTopScores, {
      params: { ...params },
    });
  }
  return await API.get(ApiRoutes.uvTopScores, {
    params: { ...params },
  });
};
export const getSignedFileUrl = async (region, bucketName, imageKey) =>
  await API.get(ApiRoutes.getSignedUrl, {
    params: {
      region,
      bucket: encodeURI(bucketName),
      key: imageKey,
    },
  });
