/* eslint-disable indent */
import { createDataContext } from './createDataContext';

const FILTER_PREFERENCES_STORAGE_KEY = 'filter_preferences';

const reducer = (state, action) => {
  switch (action.type) {
    case 'set_initialized':
      return { ...action.payload, initialized: true };
    case 'save_filter_preferences':
      return { ...state, showAdvancedFilter: action.payload };
    default:
      return state;
  }
};

const savePreferencesToLocalStorage = async (storageKey, preferences) => {
  const preferencesAsString = JSON.stringify(preferences);

  localStorage.setItem(storageKey, preferencesAsString);
};

const initService = (dispatch) => async () => {
  let filterPreferences = localStorage.getItem(FILTER_PREFERENCES_STORAGE_KEY);

  if (filterPreferences) {
    dispatch({ type: 'set_initialized', payload: JSON.parse(filterPreferences) });

    return;
  }

  filterPreferences = {
    showAdvancedFilter: false,
  };
  await savePreferencesToLocalStorage(FILTER_PREFERENCES_STORAGE_KEY, filterPreferences);

  dispatch({ type: 'set_initialized', payload: filterPreferences });
};

const showAdvancedFilter = (dispatch) => async (payload) => {
    const filterPreferences = {
      showAdvancedFilter: payload,
    };

    await savePreferencesToLocalStorage(FILTER_PREFERENCES_STORAGE_KEY, filterPreferences);

    dispatch({ type: 'save_filter_preferences', payload });
};

export const { Context, Provider } = createDataContext(
  reducer,
  {
    initService,
    showAdvancedFilter,
  },
  {
    showAdvancedFilter: false,
    initialized: false,
  },
);
