import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Divider, Typography, Tooltip, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tooltipContent: {
    padding: theme.spacing(2),
    // maxWidth: 400,
  },
  tooltipContentXpol: {
    padding: theme.spacing(2),
    //maxWidth: 1000,
    width: 1000,
    border: 'none',
    boxShadow: 'none',
  },
  tooltipItem: {
    marginBottom: theme.spacing(1),
    fontSize: '0.875rem',
  },
  tooltipLabel: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '0.875rem',
  },
  sectionTitle: {
    marginTop: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '0.875rem',
    textDecoration: 'underline',
  },
  scannerContainer: {
    display: 'flex',
    alignItems: 'flex-start', // Align content vertically on top
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
    height: 152,
  },
  scannerImage: {
    marginRight: theme.spacing(2),
    width: 30,
    height: 52,
  },
  scannerDetails: {
    justifyContent: 'start',
    display: 'flex',
    flexDirection: 'column',
  },
  scannerDetail: {
    justifyContent: 'start',
    marginBottom: theme.spacing(1),
  },
  neutralRow: {
    backgroundColor: 'transparent',
    padding: theme.spacing(2),
  },
  greyRow: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
  },
}));

const ScannerUv = ({ scannerData, rowClass, onClick }) => {
  const classes = useStyles();
  const { scanner: id, store, scansCount, uvResult } = scannerData;

  const handleClick = () => {
    if (onClick) {
      const zScoreMapping = {
        zBlack: 'black',
        zLiftBlack: 'lift_black',
        zLiftOff: 'lift_off',
      };

      const maxZScore = Math.max(
        zScore?.zBlack || 0,
        zScore?.zLiftBlack || 0,
        zScore?.zLiftOff || 0,
      );

      const maxZScoreKey = Object.keys(zScoreMapping).find(
        (key) => zScore[key] === maxZScore,
      );

      onClick(id, zScoreMapping[maxZScoreKey]);
    }
  };

  const { zScore, avg, prediction } = uvResult || {};

  return (
    <Tooltip
      title={
        <Paper className={classes.tooltipContent}>
          <Typography variant="h6" className={classes.tooltipItem}>
            Scanner Details
          </Typography>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.sectionTitle}>
              Z Scores
            </Grid>
            <Grid item xs={6} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>z-Black:</Typography>
              <Typography>{zScore?.zBlack?.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={6} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                z-LiftBlack:
              </Typography>
              <Typography>{zScore?.zLiftBlack?.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={6} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                z-LiftOff:
              </Typography>
              <Typography>{zScore?.zLiftOff?.toFixed(2)}</Typography>
            </Grid>

            <Grid item xs={12} className={classes.sectionTitle}>
              Averages
            </Grid>
            <Grid item xs={6} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                avg.Black:
              </Typography>
              <Typography>{(avg?.avgBlack * 100).toFixed(2)}%</Typography>
            </Grid>
            <Grid item xs={6} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                avg.LiftBlack:
              </Typography>
              <Typography>{(avg?.avgLiftBlack * 100).toFixed(2)}%</Typography>
            </Grid>
            <Grid item xs={6} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                avg.LiftOff:
              </Typography>
              <Typography>{(avg?.avgLiftOff * 100).toFixed(2)}%</Typography>
            </Grid>

            <Grid item xs={12} className={classes.sectionTitle}>
              Predictions
            </Grid>
            <Grid item xs={6} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>Black:</Typography>
              <Typography>{prediction?.black ? 'true' : 'false'}</Typography>
            </Grid>
            <Grid item xs={6} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                Lift Off:
              </Typography>
              <Typography>{prediction?.liftOff ? 'true' : 'false'}</Typography>
            </Grid>
            <Grid item xs={6} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                Lift Black:
              </Typography>
              <Typography>
                {prediction?.liftBlack ? 'true' : 'false'}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      }
    >
      <div
        className={`${classes.scannerContainer} ${rowClass}`}
        onClick={handleClick}
      >
        <img
          src={window.location.origin + '/Scanner.png'}
          alt="scanner"
          className={classes.scannerImage}
        />
        <div className={classes.scannerDetails}>
          <span className={classes.scannerDetail}>ID: {id}</span>
          <span className={classes.scannerDetail}>
            Store - {store && store !== 'null' ? store : 'N/A'}
          </span>
          <span className={classes.scannerDetail}>
            ZScore: {zScore?.maxZScore?.toFixed(2)}
          </span>
          <span className={classes.scannerDetail}>
            Scans Count: {scansCount}
          </span>
        </div>
      </div>
    </Tooltip>
  );
};

const ScannerXpol = ({ scannerData, rowClass, onClick }) => {
  const classes = useStyles();
  const { scanner: id, store, scansCount, xpolResult } = scannerData;

  const handleClick = () => {
    if (onClick) {
      const zScoreMapping = {
        zComplexInflamation: 'complex_inflamation',
        zForeignObject: 'foreign_object',
        // zHair: 'hair',
        zIncorrectPlacement: 'incorrect_placement',
        // zMakeup: 'makeup',
        zOutOfFocus: 'out_of_focus',
        zNotSkin: 'not_skin',
        // zTatoo: 'tatoo',
      };

      const maxZScore = Math.max(
        // zScore?.zComplexInflamation || 0,
        zScore?.zForeignObject || 0,
        // zScore?.zHair || 0,
        zScore?.zIncorrectPlacement || 0,
        // zScore?.zMakeup || 0,
        zScore?.zOutOfFocus || 0,
        // zScore?.zTatoo || 0,
        zScore?.zNotSkin || 0,
      );

      const maxZScoreKey = Object.keys(zScoreMapping).find(
        (key) => zScore[key] === maxZScore,
      );

      onClick(id, zScoreMapping[maxZScoreKey]);
    }
  };

  const { zScore, avg, prediction } = xpolResult || {};

  return (
    <Tooltip
      title={
        <Paper className={classes.tooltipContentXpol} elevation={0}>
          <Typography variant="h6" className={classes.tooltipItem}>
            Scanner Details
          </Typography>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.sectionTitle}>
              Z Scores
            </Grid>
            {/* <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                ComplexInflamation:
              </Typography>
              <Typography>{zScore?.zComplexInflamation?.toFixed(2)}</Typography>
            </Grid> */}
            <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                ForeignObject:
              </Typography>
              <Typography>{zScore?.zForeignObject?.toFixed(2)}</Typography>
            </Grid>
            {/* <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>Hair:</Typography>
              <Typography>{zScore?.zHair?.toFixed(2)}</Typography>
            </Grid> */}
            <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                IncorrectPlacement:
              </Typography>
              <Typography>{zScore?.zIncorrectPlacement?.toFixed(2)}</Typography>
            </Grid>
            {/* <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>Makeup:</Typography>
              <Typography>{zScore?.zMakeup?.toFixed(2)}</Typography>
            </Grid> */}
            <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                OutOfFocus:
              </Typography>
              <Typography>{zScore?.zOutOfFocus?.toFixed(2)}</Typography>
            </Grid>
            {/* <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>Tatoo:</Typography>
              <Typography>{zScore?.zTatoo?.toFixed(2)}</Typography>
            </Grid> */}
            <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                Not Skin:
              </Typography>
              <Typography>{zScore?.zNotSkin?.toFixed(2)}</Typography>
            </Grid>

            <Grid item xs={12} className={classes.sectionTitle}>
              Averages
            </Grid>
            {/* <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                ComplexInflamation:
              </Typography>
              <Typography>
                {(avg?.avgComplexInflamation * 100).toFixed(2)}%
              </Typography>
            </Grid> */}
            <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                ForeignObject:
              </Typography>
              <Typography>
                {(avg?.avgForeignObject * 100).toFixed(2)}%
              </Typography>
            </Grid>
            {/* <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>Hair:</Typography>
              <Typography>{(avg?.avgHair * 100).toFixed(2)}%</Typography>
            </Grid> */}
            <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                IncorrectPlacement:
              </Typography>
              <Typography>
                {(avg?.avgIncorrectPlacement * 100).toFixed(2)}%
              </Typography>
            </Grid>
            {/* <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>Makeup:</Typography>
              <Typography>{(avg?.avgMakeup * 100).toFixed(2)}%</Typography>
            </Grid> */}
            <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                OutOfFocus:
              </Typography>
              <Typography>{(avg?.avgOutOfFocus * 100).toFixed(2)}%</Typography>
            </Grid>
            {/* <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>Tatoo:</Typography>
              <Typography>{(avg?.avgTatoo * 100).toFixed(2)}%</Typography>
            </Grid> */}
            <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                Not skin:
              </Typography>
              <Typography>{(avg?.avgNotSkin * 100).toFixed(2)}%</Typography>
            </Grid>

            <Grid item xs={12} className={classes.sectionTitle}>
              Predictions
            </Grid>
            {/* <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                ComplexInflamation:
              </Typography>
              <Typography>
                {prediction?.complexInflamation ? 'true' : 'false'}
              </Typography>
            </Grid> */}
            <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                ForeignObject:
              </Typography>
              <Typography>
                {prediction?.foreignObject ? 'true' : 'false'}
              </Typography>
            </Grid>
            {/* <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>Hair:</Typography>
              <Typography>{prediction?.hair ? 'true' : 'false'}</Typography>
            </Grid> */}
            <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                IncorrectPlacement:
              </Typography>
              <Typography>
                {prediction?.incorrectPlacement ? 'true' : 'false'}
              </Typography>
            </Grid>
            {/* <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>Makeup:</Typography>
              <Typography>{prediction?.makeup ? 'true' : 'false'}</Typography>
            </Grid> */}
            <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                OutOfFocus:
              </Typography>
              <Typography>
                {prediction?.outOfFocus ? 'true' : 'false'}
              </Typography>
            </Grid>
            {/* <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>Tatoo:</Typography>
              <Typography>{prediction?.tatoo ? 'true' : 'false'}</Typography>
            </Grid> */}
            <Grid item xs={4} className={classes.tooltipItem}>
              <Typography className={classes.tooltipLabel}>
                Not Skin:
              </Typography>
              <Typography>{prediction?.notSkin ? 'true' : 'false'}</Typography>
            </Grid>
          </Grid>
        </Paper>
      }
    >
      <div
        className={`${classes.scannerContainer} ${rowClass}`}
        onClick={handleClick}
      >
        <img
          src={window.location.origin + '/Scanner.png'}
          alt="scanner"
          className={classes.scannerImage}
        />
        <div className={classes.scannerDetails}>
          <span className={classes.scannerDetail}>ID: {id}</span>
          <span className={classes.scannerDetail}>
            Store - {store && store !== 'null' ? store : 'N/A'}
          </span>
          <span className={classes.scannerDetail}>
            ZScore: {zScore?.maxZScore?.toFixed(2)}
          </span>
          <span className={classes.scannerDetail}>
            From scans: {scansCount}
          </span>
        </div>
      </div>
    </Tooltip>
  );
};

const ScannerCount = ({ scannerData, rowClass, onClick }) => {
  const classes = useStyles();
  const { scanner: id, store, suspiciousAnomalies } = scannerData;

  const sortedOutAnomalies = suspiciousAnomalies?.sort(
    (a, b) => b.count - a.count,
  );

  //   const maxAnomaly = sortedOutAnomalies;
  //     suspiciousAnomalies?.reduce(
  //       (max, item) => (item.count > (max.count || 0) ? item : max),
  //       {},
  //     ).anomaly || '';

  const maxAnomaly = sortedOutAnomalies ? sortedOutAnomalies[0].anomaly : '';

  const handleClick = () => {
    if (onClick) {
      onClick(id, maxAnomaly);
    }
  };

  const anomalies = sortedOutAnomalies || [];

  return (
    <div
      className={`${classes.scannerContainer} ${rowClass}`}
      onClick={handleClick}
    >
      <img
        src={window.location.origin + '/Scanner.png'}
        alt="scanner"
        className={classes.scannerImage}
      />
      <div className={classes.scannerDetails}>
        <span className={classes.scannerDetail}>
          {id} (Store: {store && store !== 'null' ? store : 'N/A'})
        </span>
        {anomalies?.map((item, index) => (
          <span className={classes.scannerDetail}>
            {item.anomaly}: {item.count}
          </span>
        ))}
      </div>
    </div>
  );
};

export { ScannerUv, ScannerXpol };

const ScannerList = ({
  algorithm,
  model,
  scanners,
  isLoading,
  onScannerClick,
}) => {
  const classes = useStyles();

  const handleScannerClick = (id, propertyName) => {
    console.log('Scanner clicked:', id, propertyName);
    if (onScannerClick) {
      onScannerClick(id, propertyName);
    }
  };

  return (
    <>
      <Grid container spacing={0}>
        {scanners?.map((scanner, index) => {
          const rowIndex = Math.floor(index / 4);
          const rowClass =
            rowIndex % 2 === 0 ? classes.neutralRow : classes.greyRow;

          return (
            <Grid item xs={12} sm={6} md={3} key={scanner.id}>
              {algorithm === 'zScore' ? (
                model === 'uv' ? (
                  <ScannerUv
                    scannerData={scanner}
                    rowClass={rowClass}
                    onClick={handleScannerClick}
                  />
                ) : (
                  <ScannerXpol
                    scannerData={scanner}
                    rowClass={rowClass}
                    onClick={handleScannerClick}
                  />
                )
              ) : null}
              {algorithm === 'count' && (
                <ScannerCount
                  scannerData={scanner}
                  rowClass={rowClass}
                  onClick={handleScannerClick}
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default ScannerList;
