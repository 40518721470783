import { translate as t } from 'utils/translate';
import { Config } from 'configs';

export const getNavLinks = () => {
  const { ROLES } = Config;
  const { AUTHENTICATED, UNAUTHENTICATED, ADMIN } = ROLES;

  // return [
  //   {
  //     title: 'XPOL',
  //     to: '/jobs/xpol',
  //     languages: ['en', 'it'],
  //     roles: [AUTHENTICATED, ADMIN],
  //   },
  //   {
  //     title: 'UV',
  //     to: '/jobs/uv',
  //     languages: ['en', 'it'],
  //     roles: [AUTHENTICATED, ADMIN],
  //   },
  //   {
  //     title: 'Data Sets',
  //     to: '/datasets',
  //     languages: ['en', 'it'],
  //     roles: [AUTHENTICATED, ADMIN],
  //   },
  //   {
  //     title: 'Anomalies Detection',
  //     to: '/anomalies-detection',
  //     languages: ['en', 'it'],
  //     roles: [AUTHENTICATED, ADMIN],
  //   },
  //   {
  //     title: 'Feedback',
  //     to: '/feedback',
  //     languages: ['en', 'it'],
  //     roles: [AUTHENTICATED, ADMIN],
  //   },
  //   {
  //     title: t('login'),
  //     to: '/sign-in',
  //     languages: ['en', 'it'],
  //     roles: [UNAUTHENTICATED],
  //     iconName: '/prisijungti_icon.png',
  //   },
  // ];
  return [
    {
      title: 'Portals',
      to: '/portals',
      languages: ['en', 'it'],
      roles: [AUTHENTICATED, ADMIN],
    },
    {
      title: 'Statistics',
      to: '/statistics/menu',
      languages: ['en', 'it'],
      roles: [AUTHENTICATED, ADMIN],
    },
    {
      title: 'Anomalies',
      to: '/anomalies/menu',
      languages: ['en', 'it'],
      roles: [AUTHENTICATED, ADMIN],
    },
    {
      title: 'QAT',
      to: '/qat',
      languages: ['en', 'it'],
      roles: [AUTHENTICATED, ADMIN],
    },
  ];
};
