import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Link, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CircularLoader } from 'components/Controls';
import { getUploads } from 'api';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';

// import { getCustomers } from './controls/customers';
// import MyMongoChart from './mongo';

export const PortalsMenuScreen = ({ location }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: '10px',
    },
    header: {
      backgroundColor: 'lightgray',
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    headerRight: {
      backgroundColor: 'lightgray',
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
      textAlign: 'right',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'start',
      color: theme.palette.text.secondary,
    },
    paperLeft: {
      padding: theme.spacing(1),
      textAlign: 'left',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
    },
    paperRight: {
      padding: theme.spacing(1),
      textAlign: 'right',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
    },
    pageTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 24,
      fontWeight: 'bold',
    },
    categoryTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 18,
      fontWeight: 'bold',
    },
    cell: {
      width: '100%',
    },
    thresholdCell: {
      ...theme.legendCell,
      backgroundColor: '#e2f1f8',
    },
    thresholdCellAlt: {
      ...theme.legendCell,
      backgroundColor: '#c1d5e0',
    },
    selectInput: {
      //zIndex: 100,
      marginTop: 16,
      marginBottom: 8,
      marginLeft: 10,
      marginRight: 10,
    },
    paperBold: {
      width: '110px',
      padding: theme.spacing(1),
      fontSize: 14,
      backgroundColor: '#e0ffee',
      fontWeight: 'bold',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    csvLink: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <Typography className={classes.pageTitle} align="left">
          USA (Oregon)
        </Typography>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '280px 280px 280px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <div>
          <Paper className={classes.header}>
            <span>Client</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Portal</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>&nbsp;</span>
          </Paper>
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '280px 280px 280px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>Sephora NA</Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link href="https://sephora-na-v2.fitskin.com/" target="_blank">
              https://sephora-na-v2.fitskin.com/
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link
              href="https://sephora-na-api.fitskin.com/metadata/"
              target="_blank"
            >
              API
            </Link>
            {' /'}
            <Link href="https://sephora-na-staging.fitskin.com" target="_blank">
              DEV
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>BlushBar</Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link href="https://blushbar.fitskin.com/" target="_blank">
              https://blushbar.fitskin.com/
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link
              href="https://blushbar-api.fitskin.com/metadata/"
              target="_blank"
            >
              API
            </Link>
            {' /'}
            <Link href="https://blushbar-staging.fitskin.com" target="_blank">
              DEV
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>Abbvie</Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link href="https://abbvie.fitskin.com/" target="_blank">
              https://abbvie.fitskin.com/
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link
              href="https://abbvie-api.fitskin.com/metadata/"
              target="_blank"
            >
              API
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>JPMS</Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link href="https://jpms.fitskin.com/" target="_blank">
              https://jpms.fitskin.com/
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link href="https://jpms-api.fitskin.com/metadata/" target="_blank">
              API
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>Superpharm</Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link href="https://superpharm-v2.fitskin.com/" target="_blank">
              https://superpharm-v2.fitskin.com/
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link
              href="https://superpharm-api.fitskin.com/metadata/"
              target="_blank"
            >
              API
            </Link>
            {' /'}
            <Link href="https://superpharm-staging.fitskin.com" target="_blank">
              DEV
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>Ulta</Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link href="https://ulta.fitskin.com/" target="_blank">
              https://ulta.fitskin.com/
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link href="https://ulta-api.fitskin.com/metadata/" target="_blank">
              API
            </Link>
            {' /'}
            <Link href="https://ulta-staging.fitskin.com" target="_blank">
              DEV
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>WBA (No7)</Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link href="https://wba-us.fitskin.com/" target="_blank">
              https://wba-us.fitskin.com/
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link
              href="https://wba-api-us.fitskin.com/metadata/"
              target="_blank"
            >
              API
            </Link>
          </Paper>
        </Grid>
      </div>
      <div>
        <Typography className={classes.pageTitle} align="left">
          Asia-Pacific (Singapore)
        </Typography>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '280px 280px 280px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <div>
          <Paper className={classes.header}>
            <span>Client</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Portal</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>&nbsp;</span>
          </Paper>
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '280px 280px 280px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>Sephora SEA</Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link href="https://sephora-sea-v2.fitskin.com/" target="_blank">
              https://sephora-sea-v2.fitskin.com/
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link
              href="https://sephora-sea-api.fitskin.com/metadata/"
              target="_blank"
            >
              API
            </Link>
            {' /'}
            <Link
              href="https://sephora-sea-staging.fitskin.com"
              target="_blank"
            >
              DEV
            </Link>
          </Paper>
        </Grid>
      </div>
      <div>
        <Typography className={classes.pageTitle} align="left">
          Asia-Pacific (China)
        </Typography>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '280px 280px 280px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <div>
          <Paper className={classes.header}>
            <span>Client</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Portal</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>&nbsp;</span>
          </Paper>
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '280px 280px 280px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>Sephora China</Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link href="https://sephora-china.fitskin.com/" target="_blank">
              https://sephora-china.fitskin.com/
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link
              href="https://sephora-china-api.fitskin.com/metadata/"
              target="_blank"
            >
              API
            </Link>
            {' /'}
            <Link
              href="https://sephora-china-staging.fitskin.com"
              target="_blank"
            >
              DEV
            </Link>
          </Paper>
        </Grid>
      </div>
      <div>
        <Typography className={classes.pageTitle} align="left">
          Asia-Pacific (Sydney)
        </Typography>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '280px 280px 280px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <div>
          <Paper className={classes.header}>
            <span>Client</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Portal</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>&nbsp;</span>
          </Paper>
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '280px 280px 280px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>Skin Care (Atomica logo)</Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link href="https://skin-care.fitskin.com/" target="_blank">
              https://skin-care.fitskin.com/
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link
              href="https://skin-care-api.fitskin.com/metadata/"
              target="_blank"
            >
              API
            </Link>
            {' /'}
            <Link href="https://skin-care-staging.fitskin.com" target="_blank">
              DEV
            </Link>
          </Paper>
        </Grid>
      </div>
      <div>
        <Typography className={classes.pageTitle} align="left">
          Europe (London)
        </Typography>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '280px 280px 280px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <div>
          <Paper className={classes.header}>
            <span>Client</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Portal</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>&nbsp;</span>
          </Paper>
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '280px 280px 280px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>Sephora EMEA</Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link href="https://sephora-emea-v2.fitskin.com/" target="_blank">
              https://sephora-emea-v2.fitskin.com/
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link
              href="https://sephora-emea-api.fitskin.com/metadata/"
              target="_blank"
            >
              API
            </Link>
            {' /'}
            <Link
              href="https://sephora-emea-staging.fitskin.com"
              target="_blank"
            >
              DEV
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>WBA (No7)</Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link href="https://wba-eu-london.fitskin.com/" target="_blank">
              https://wba-eu-london.fitskin.com/
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link href="https://wba-api.fitskin.com/metadata/" target="_blank">
              API
            </Link>
            {' /'}
            <Link href="https://wba-staging-v2.fitskin.com" target="_blank">
              DEV
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>Chanel</Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link href="https://chanel.fitskin.com/" target="_blank">
              https://chanel.fitskin.com/
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link
              href="https://chanel-api.fitskin.com/metadata/"
              target="_blank"
            >
              API
            </Link>
          </Paper>
        </Grid>
      </div>
      <div>
        <Typography className={classes.pageTitle} align="left">
          Europe (Nordics)
        </Typography>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '280px 280px 280px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <div>
          <Paper className={classes.header}>
            <span>Client</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Portal</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>&nbsp;</span>
          </Paper>
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '280px 280px 280px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>WBA (No7)</Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>&bnsp;</Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link
              href="https://wba-api-eu-north.fitskin.com/metadata/"
              target="_blank"
            >
              API
            </Link>
          </Paper>
        </Grid>
      </div>
      <div>
        <Typography className={classes.pageTitle} align="left">
          Middle-East (Bahrain)
        </Typography>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '280px 280px 280px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <div>
          <Paper className={classes.header}>
            <span>Client</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Portal</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>&nbsp;</span>
          </Paper>
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '280px 280px 280px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>WBA (No7)</Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>&bnsp;</Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link
              href="https://wba-api-me.fitskin.com.fitskin.com/metadata/"
              target="_blank"
            >
              API
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>Sephora ME</Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link href="https://sephora-me.fitskin.com/" target="_blank">
              https://sephora-me.fitskin.com/
            </Link>
          </Paper>
        </Grid>
        <Grid key={0} item className={classes.cell}>
          <Paper className={classes.paper}>
            <Link
              href="https://sephora-me-api.fitskin.com/metadata/"
              target="_blank"
            >
              API
            </Link>
            {' /'}
            <Link href="https://sephora-me-staging.fitskin.com" target="_blank">
              DEV
            </Link>
          </Paper>
        </Grid>
      </div>
    </div>
  );
};
