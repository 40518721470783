import API, { ApiRoutes } from 'components/API';
import { Config } from 'configs';

export const getDistinctSourceTypesByClient = async (clientName) => {
  if (!clientName) {
    throw Error('Please provide client name');
  }

  let apiUrl = `${ApiRoutes.getSourceTypes}?client=${encodeURI(clientName)}`;
  const getResourceTypesResponse = await API.get(apiUrl);
  if (getResourceTypesResponse) {
    return getResourceTypesResponse;
  }
  return null;
};

export const getScans = async (client, year, month, reportType, item) => {
  let apiUrl = `${ApiRoutes.getScans}`;

  if (client) {
    apiUrl += `?client=${client}`;
  }

  if (year && month) {
    apiUrl += `&filter=year:${year}%3Bmonth:${month}`;
  }
  if (reportType) {
    apiUrl += `&reportType=${reportType}`;
  }
  if (item) {
    apiUrl += `&item=${item}`;
  }
  console.log('API URL', apiUrl);
  return await API.get(apiUrl);
};

// export const getScores = async (sourceType, year, month) => {
//   if (!year) {
//     throw Error('Please provide month');
//   }
//   if (!year) {
//     throw Error('Please provide month');
//   }

//   const wbaXPolScoresResponse = await API.get(
//     `${ApiRoutes.getWbaXpolScores}?source=${sourceType}&year=${year}&month=${month}`,
//   );
//   if (wbaXPolScoresResponse) {
//     return wbaXPolScoresResponse.result;
//   }
//   return [];
// };

export const getUploads = async () => {
  const response = await API.get(`${ApiRoutes.getUploadsDetails}`);
  return response || [];
};

export const getDistinctAppVersions = async (customer) => {
  if (!customer) {
    throw Error('Please provide client');
  }
  const getAppVersionsResponse = await API.get(
    `${ApiRoutes.getAppVersions}?client=${customer}`,
  );
  if (getAppVersionsResponse) {
    return getAppVersionsResponse;
  }
  return [];
};

export const getDistinctScanners = async (customer) => {
  if (!customer) {
    throw Error('Please provide client');
  }
  const getScannersResponse = await API.get(
    `${ApiRoutes.getScanners}?client=${customer}`,
  );
  if (getScannersResponse) {
    return getScannersResponse;
  }
  return [];
};

export const getScannersUsageByAppVersion = async (customer, appVersion) => {
  return await API.get(
    `${ApiRoutes.getAppVersionScanners}?version=${encodeURI(
      appVersion,
    )}&client=${customer}`,
  );
};

export const getDistinctStores = async (customer) => {
  if (!customer) {
    throw Error('Please provide client');
  }
  const getStoresResponse = await API.get(
    `${ApiRoutes.getStores}?client=${customer}`,
  );
  if (getStoresResponse) {
    return getStoresResponse;
  }
  return [];
};

export const getScannersUsageByStores = async (
  customer,
  year,
  month,
  store,
) => {
  if (!customer) {
    throw Error('Please provide client');
  }
  if (!year) {
    throw Error('Please provide year');
  }
  if (!month) {
    throw Error('Please provide month');
  }

  let apiUrl = `${ApiRoutes.getStoreScanners}?client=${customer}&year=${year}&month=${month}`;

  if (store) {
    apiUrl += `&storeId=${store}`;
  }
  const getStoresResponse = await API.get(apiUrl);
  if (getStoresResponse) {
    return getStoresResponse;
  }
  return [];
};
