import React, { useEffect, useState } from 'react';
import { Paper, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CircularLoader } from 'components/Controls';
import { createDataSet, getDataSets } from 'api';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useHistory } from 'react-router-dom';
import { DataSetAddNewForm } from './new';

export const DataSetsScreen = ({ location }) => {
  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      //marginLeft: theme.spacing(2),
      border: '1px solid rgba(0, 0, 0, 0.12)',
      padding: '10px',
    },
    pageTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 24,
      fontWeight: 'bold',
    },
    warning: {
      color: 'red',
      padding: theme.spacing(1),
      fontSize: 16,
      fontWeight: 'bold',
    },
  }));
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const [showAddNewForm, setShowAddNewForm] = useState(false);

  const listIsVisible = !showAddNewForm;

  useEffect(() => {
    (async function fetchDataSets() {
      try {
        setIsLoading(true);
        const dataSets = await getDataSets();
        setData(dataSets);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const onSaveNewDataSet = async (data) => {
    try {
      setIsLoading(true);

      const response = await createDataSet(data);
      console.log('response', response);
      if (response) {
        history.push(`/anomalies/data-sets/${response}?name=${data.name}`);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const renderList = () => {
    return (
      <div>
        <div>
          <Typography className={classes.pageTitle} align="left">
            Data Sets
          </Typography>
          <Button
            type="button"
            width="170px"
            variant="contained"
            color="primary"
            onClick={() => {
              setShowAddNewForm(true);
            }}
            style={{ marginRight: '40px' }}
          >
            Add new Data Set
          </Button>
        </div>
        {!isLoading && (
          <div style={{ height: 400, width: '100%', marginTop: 30 }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Data Set Name</TableCell>
                    <TableCell>Model Type</TableCell>
                    <TableCell>Images Count</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((row) => (
                    <TableRow
                      key={row._id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {/* <TableCell component="th" scope="row">
                      {`${new Date(row.date).toLocaleTimeString()} ${new Date(
                        row.date,
                      ).toDateString()}`}
                    </TableCell> */}
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell>{row.model_type}</TableCell>
                      <TableCell>{row.images}</TableCell>
                      <TableCell>
                        <a
                          href={`/anomalies/data-sets/${
                            row.id
                          }?name=${encodeURI(row.name)}`}
                        >
                          View
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
        {isLoading && <CircularLoader />}
      </div>
    );
  };

  const renderAddNewForm = () => {
    return (
      <div>
        <Typography className={classes.pageTitle} align="left">
          Add New Data Set
        </Typography>
        <Button
          type="button"
          width="170px"
          variant="outlined"
          color="primary"
          onClick={() => {
            setShowAddNewForm(false);
          }}
          style={{ marginRight: '40px' }}
        >
          Back to Data Sets
        </Button>
        <DataSetAddNewForm
          onCancel={() => setShowAddNewForm(false)}
          onSave={(data) => onSaveNewDataSet(data)}
        />
      </div>
    );
  };

  return (
    <div>
      {listIsVisible && renderList()}
      {showAddNewForm && renderAddNewForm()}
    </div>
  );
};
