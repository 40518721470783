import API, { ApiRoutes } from 'components/API';

export const validateScanners = async (scannersToCheck) => {
  if (!scannersToCheck) {
    throw Error('Please provide scanners to check');
  }

  const qatApiResponse = await API.post(ApiRoutes.createQatValidation, {
    scanners: scannersToCheck,
  });
  if (qatApiResponse) {
    return qatApiResponse.data;
  }

  return null;
};

export const postQatResults = async (
  scannersToCheck,
  validationResult,
  fileName,
) => {
  if (!scannersToCheck) {
    throw Error('Please provide scannersToCheck');
  }
  if (!validationResult) {
    throw Error('Please provide validationResult');
  }

  const postPairResponse = await API.post(ApiRoutes.qat, {
    scanners: scannersToCheck,
    response: validationResult,
    fileName,
  });
  if (postPairResponse) {
    return postPairResponse.result;
  }
  return false;
};

export const getQatValidationResults = async () => {
  const validationResults = await API.get(ApiRoutes.qat);
  if (validationResults) {
    return validationResults;
  }
  return [];
};
export const getQatValidationItem = async (id) => {
  const validationItem = await API.get(ApiRoutes.qatItem(id));

  if (validationItem) {
    return validationItem;
  }
  return [];
};
