import React, { useEffect, useRef } from 'react';
import {
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { getUserMenuLinks } from '../UserMenuLinks';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  button: {
    marginBottom: 6,
    height: '30px',
    marginTop: '30px',
    textTransform: 'none',
    paddingBottom: 6,
    paddingLeft: 10,
    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '80%',
      paddingBottom: 12,
      marginLeft: 5,
      paddingLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  icon: {
    color: 'inherit',
    paddingLeft: 3,
  },
  item: {
    fontSize: 13,
    paddingTop: 2,
  },
}));

export const UserMenu = ({ displayName }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef(null);
  const userMenuLinks = getUserMenuLinks();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Button
        className={classes.button}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <div style={{ marginRight: 6, marginTop: 4, marginLeft: 10 }}>
          {/* {<img src="/prisijungti_icon.png" alt="" />} */}
        </div>
        {displayName}
        <ArrowDropDownIcon className={classes.icon} color="primary" />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 5000 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                  {userMenuLinks.map(({ title, onClick, includeDivider }) => (
                    <div key={title}>
                      {includeDivider && <Divider />}
                      <MenuItem className={classes.item} onClick={onClick} dense>
                        {title}
                      </MenuItem>
                    </div>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
