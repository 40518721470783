import { Config } from 'configs';

export const getClients = (includeNone) => {
  const clients = [
    Config.AUTH.SEPHORA_NA,
    Config.AUTH.SEPHORA_SEA,
    Config.AUTH.SEPHORA_EMEA,
    Config.AUTH.WBA,
    Config.AUTH.JPMS,
    Config.AUTH.SUPERPHARM,
    Config.AUTH.BLUSHBAR,
    Config.AUTH.ULTA,
  ];

  if (includeNone !== undefined && includeNone) {
    clients.unshift({
      ID: -1,
      NAME: 'None',
    });
  }
  return clients;
};

export const getCustomersThatRunsML = () => [
  {
    ID: -1,
    NAME: 'None',
  },
  Config.AUTH.SEPHORA_NA,
  Config.AUTH.WBA,
];
