import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CircularLoader } from 'components/Controls';
import BarChartIcon from '@material-ui/icons/BarChart';
import { getUvJobs } from 'api';
import { Form } from 'components/Controls';
import Select from 'react-select';
import { Context as AuthContext } from 'contexts/AuthContext';
import { Config } from 'configs';
import { AnomaliesMongoDbDashboardLinks } from 'routes';
import { history } from 'utils/history';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';

export const MLUvScreen = ({ location }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      //marginLeft: theme.spacing(2),
      border: '1px solid rgba(0, 0, 0, 0.12)',
      padding: '10px',
      minWidth: 400,
      maxWidth: 400,
    },
    header: {
      backgroundColor: 'lightgray',
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paperLeft: {
      padding: theme.spacing(1),
      textAlign: 'left',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
    },
    paperRight: {
      padding: theme.spacing(1),
      textAlign: 'right',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
      // textDecoration: 'underline',
    },
    pageTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 24,
      fontWeight: 'bold',
    },
    categoryTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 18,
      fontWeight: 'bold',
    },
    cell: {
      width: '100%',
    },
    thresholdCell: {
      ...theme.legendCell,
      backgroundColor: '#e2f1f8',
    },
    thresholdCellAlt: {
      ...theme.legendCell,
      backgroundColor: '#c1d5e0',
    },
    selectInput: {
      //zIndex: 100,
      marginTop: 16,
      marginBottom: 8,
      marginLeft: 10,
      marginRight: 10,
    },
    paperBold: {
      width: '110px',
      padding: theme.spacing(1),
      fontSize: 14,
      backgroundColor: '#e0ffee',
      fontWeight: 'bold',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    csvLink: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  const getDashboardLink = (type) => {
    const dashboard = AnomaliesMongoDbDashboardLinks.find(
      (item) => item.client === selectedCustomer?.value,
    );

    if (dashboard) {
      return dashboard.type[type];
    }

    return '';
  };

  const { state: authState } = useContext(AuthContext);

  const [customers] = useState([
    { value: 'wba', label: 'WBA' },
    { value: 'sephora_na', label: 'Sephora NA' },
  ]);
  const [uvJobs, setUvJobs] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const hasData = uvJobs != null && uvJobs.length > 0;

  const fetchSephoraChart = useCallback(async (chartId, placementId) => {
    setIsLoading(true);

    const sdk = new ChartsEmbedSDK({
      baseUrl: 'https://charts.mongodb.com/charts-warehouse-uhqyz',
    });

    const chart = sdk.createChart({
      chartId: chartId,
      showAttribution: false,
      filter: {},
      autoRefresh: false,
      maxDataAge: 86400,
    });

    await chart.render(document.getElementById(placementId));

    setIsLoading(false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      if (selectedCustomer) {
        const uv = await getUvJobs(selectedCustomer.value);
        setUvJobs(uv || []);

        if (selectedCustomer.value === 'wba') {
          fetchSephoraChart(
            'f30c39f1-3679-415c-beca-f081d41b8d80',
            'wba_confidence_image_is_valid',
          );
          fetchSephoraChart(
            'af61b0c2-d2d3-43e9-9deb-eea36ddebd41',
            'wba_ratio_valid_invalid',
          );
          fetchSephoraChart(
            '1e903352-3b12-4841-8de1-0bc9ebe4340b',
            'wba_invalid_image_categories',
          );
          fetchSephoraChart(
            '8568c8ce-12e9-42ac-82d2-a7d2ec1b7d17',
            'wba_invalid_image_categories_2',
          );
        }
        if (selectedCustomer.value === 'sephora_na') {
          fetchSephoraChart(
            'a703ee50-7f8f-4e93-be2e-ac922f874036',
            'sephora_na_confidence_image_is_valid',
          );
          fetchSephoraChart(
            'a38e409e-220f-411b-a2b7-84a8a88dda58',
            'sephora_na_ratio_valid_invalid',
          );
          fetchSephoraChart(
            'a0a9c8f3-3e10-4bdb-b05b-0bd4e27bde5f',
            'sephora_na_invalid_image_categories',
          );
          fetchSephoraChart(
            '0275a8c1-058f-48c6-8f3d-9e98f715888c',
            'sephora_na_invalid_image_categories_2',
          );
        }
      }

      setIsLoading(false);
    };
    fetchData();
  }, [selectedCustomer]);

  const compareValues = (jobValue, threshold) => {
    if (!threshold || threshold.value === null) return false;
    switch (threshold.compareBy) {
      case 'lt':
        return jobValue < threshold.value;
      case 'gt':
        return jobValue > threshold.value;
      default:
        return false;
    }
  };
  const renderDataAUv = (finishedJobs) => {
    const thresholds = getUvAnomaliesThresholds();

    let rows = [];
    if (finishedJobs && finishedJobs.length > 0) {
      finishedJobs.forEach((job) => {
        let outputCells = [];
        outputCells.push({
          value: job.date.substring(0, 10),
          isAnomalous: false,
        });
        outputCells.push({ value: job.model_version, isAnomalous: false });
        outputCells.push({
          value: job.valid,
          isAnomalous: compareValues(job.valid, thresholds.valid),
          date: job.date.substring(0, 10),
          propertyName: 'valid',
        });
        outputCells.push({
          value: job.black,
          isAnomalous: compareValues(job.black, thresholds.black),
          date: job.date.substring(0, 10),
          propertyName: 'black',
        });
        outputCells.push({
          value: job.lift_black,
          isAnomalous: compareValues(job.lift_black, thresholds.lift_black),
          date: job.date.substring(0, 10),
          propertyName: 'lift_black',
        });
        outputCells.push({
          value: job.lift_off,
          isAnomalous: compareValues(job.lift_off, thresholds.lift_off),
          date: job.date.substring(0, 10),
          propertyName: 'lift_off',
        });
        // outputCells.push({
        //   value: job.missing,
        //   isAnomalous: compareValues(job.missing, thresholds.missing),
        //   date: job.date.substring(0, 10),
        //   propertyName: 'missing',
        // });
        rows.push(outputCells);
      });
    }

    return rows.map((row, index) => (
      <div
        key={index}
        style={{
          display: 'grid',
          gridTemplateColumns: '180px 80px 120px 120px 120px 150px 180px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        {row.map((cell, cellIndex) => (
          <Grid key={cellIndex} item className={classes.cell}>
            <Paper
              className={cellIndex >= 2 ? classes.paperRight : classes.paper}
              style={{
                backgroundColor: cell.isAnomalous ? '#ffcdd2' : undefined,
                cursor: cellIndex >= 2 ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (cellIndex < 2) return;

                const { date, propertyName } = cell;
                history.push(
                  `/scores-by-date?date=${date}&propertyName=${propertyName}&type=uv&client=${selectedCustomer.value}`,
                );
              }}
            >
              {cellIndex >= 2
                ? `${(parseFloat(cell.value) * 100).toFixed(2)}%`
                : cell.value}
            </Paper>
          </Grid>
        ))}
      </div>
    ));
  };

  const getUvAnomaliesThresholds = (customer) => {
    return {
      valid: { value: 0.26, compareBy: 'lt' },
      black: { value: 0.15, compareBy: 'gt' },
      lift_black: { value: 0.15, compareBy: 'gt' },
      // missing: { value: 0.15, compareBy: 'gt' },
    };
  };

  const renderThresholdsHeaderUv = () => {
    const thresholds = getUvAnomaliesThresholds();

    const valid = (thresholds?.valid?.value || 0) * 100;
    const black = (thresholds?.black?.value || 0) * 100;
    const lift_black = (thresholds?.lift_black?.value || 0) * 100;
    // const lift_off = (thresholds?.lift_off?.value || 0) * 100;
    // const missing = (thresholds?.missing?.value || 0) * 100;

    return (
      <div style={{ marginTop: 30, marginBottom: 30 }}>
        <div style={{ paddingBottom: 10 }}>
          <Typography className={classes.categoryTitle} align="left">
            UV thresholds
          </Typography>
        </div>
        <Grid container spacing={3} style={{ marginLeft: 0 }}>
          <Grid item className={classes.thresholdCell}>
            <span>
              Valid {'<'} {valid.toFixed(2)} %
            </span>
          </Grid>
          <Grid item className={classes.thresholdCellAlt}>
            <span>
              Black {'>'} {black.toFixed(2)} %
            </span>
          </Grid>
          <Grid item className={classes.thresholdCell}>
            <span>
              Lift black {'>'} {lift_black.toFixed(2)} %
            </span>
          </Grid>
          {/* <Grid item className={classes.thresholdCellAlt}>
            <span>
              Lift off {'>'} {lift_off.toFixed(2)} %
            </span>
          </Grid> */}
          {/* <Grid item className={classes.thresholdCell}>
            <span>
              Missing {'>'} {missing.toFixed(2)} %
            </span>
          </Grid> */}
        </Grid>
      </div>
    );
  };

  const renderHeaderUv = (dates) => {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '180px 80px 120px 120px 120px 150px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <div>
          <Paper className={classes.header}>
            <span>Date</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Model</span>
          </Paper>
        </div>
        <div>
          <Paper
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              history.push(
                `/top-scores?propertyName=valid&type=uv&client=${selectedCustomer.value}`,
              );
            }}
            className={classes.header}
          >
            <span>Valid</span>
          </Paper>
        </div>
        <div>
          <Paper
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              history.push(
                `/top-scores?propertyName=black&type=uv&client=${selectedCustomer.value}`,
              );
            }}
            className={classes.header}
          >
            <span>Black</span>
          </Paper>
        </div>
        <div>
          <Paper
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              history.push(
                `/top-scores?propertyName=lift_black&type=uv&client=${selectedCustomer.value}`,
              );
            }}
            className={classes.header}
          >
            <span>Lift black</span>
          </Paper>
        </div>
        <div>
          <Paper
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              history.push(
                `/top-scores?propertyName=lift_off&type=uv&client=${selectedCustomer.value}`,
              );
            }}
            className={classes.header}
          >
            <span>Lift off</span>
          </Paper>
        </div>
        {/* <div>
          <Paper className={classes.header}>
            <span>Missing</span>
          </Paper>
        </div> */}
      </div>
    );
  };

  const renderGrid = (type) => {
    if (!hasData) {
      return null;
    }

    return (
      <div>
        <Grid item spacing={0}>
          <div>{renderThresholdsHeaderUv()}</div>

          <div>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <Typography className={classes.categoryTitle} align="left">
                Last 20 Jobs
              </Typography>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getDashboardLink(type)}
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
              >
                <BarChartIcon style={{ fontSize: '2rem' }} />
              </a>
            </div>
            <Grid item spacing={0}>
              {uvJobs && (
                <>
                  {renderHeaderUv()}
                  {renderDataAUv(uvJobs)}
                </>
              )}
            </Grid>
          </div>
        </Grid>
      </div>
    );
  };

  return (
    <div>
      <div>
        <Typography className={classes.pageTitle} align="left">
          UV
        </Typography>
      </div>
      <div className={classes.root}>
        <Form>
          <Grid container direction="column">
            {authState.user?.role === Config.ROLES.ADMIN && (
              <Grid item alignItems="center">
                <Select
                  onChange={(selectedItem) => {
                    setSelectedCustomer(selectedItem);
                  }}
                  placeholder="Select client"
                  className={classes.selectInput}
                  options={customers}
                />
              </Grid>
            )}
          </Grid>
        </Form>
      </div>
      <div style={{ marginTop: 30 }}></div>
      <div style={{ marginTop: 10 }}>
        {isLoading && (
          <CircularLoader
            doNotCenter
            customStyle={{
              flexGrow: 1,
              width: '100vw',
            }}
          />
        )}
      </div>
      {!isLoading && renderGrid('uv')}
      {selectedCustomer?.value === 'wba' && (
        <>
          <div style={{ display: 'flex', marginTop: 30, gap: '20px' }}>
            <div
              id="wba_confidence_image_is_valid"
              style={{
                width: '500px',
                height: '550px',
                display: isLoading ? 'none' : 'block',
              }}
            />
            <div
              id="wba_ratio_valid_invalid"
              style={{
                width: '500px',
                height: '550px',
                display: isLoading ? 'none' : 'block',
              }}
            />
          </div>

          <div style={{ display: 'flex', marginTop: 30, gap: '20px' }}>
            <div
              id="wba_invalid_image_categories"
              style={{
                width: '500px',
                height: '550px',
                display: isLoading ? 'none' : 'block',
              }}
            />
            <div
              id="wba_invalid_image_categories_2"
              style={{
                width: '600px',
                height: '550px',
                display: isLoading ? 'none' : 'block',
              }}
            />
          </div>
        </>
      )}

      {selectedCustomer?.value === 'sephora_na' && (
        <>
          <div style={{ display: 'flex', marginTop: 30, gap: '20px' }}>
            <div
              id="sephora_na_confidence_image_is_valid"
              style={{
                width: '400px',
                height: '450px',
                display: isLoading ? 'hidden' : 'block',
              }}
            />
            <div
              id="sephora_na_ratio_valid_invalid"
              style={{
                width: '400px',
                height: '450px',
                display: isLoading ? 'hidden' : 'block',
              }}
            />
          </div>
          <div style={{ display: 'flex', marginTop: 30, gap: '20px' }}>
            <div
              id="sephora_na_invalid_image_categories"
              style={{
                width: '400px',
                height: '450px',
                display: isLoading ? 'hidden' : 'block',
              }}
            />
            <div
              id="sephora_na_invalid_image_categories_2"
              style={{
                width: '400px',
                height: '450px',
                display: isLoading ? 'hidden' : 'block',
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};
