import React from 'react';
import Modal from '@material-ui/core/Modal';

export const SimpleModal = React.forwardRef((props, ref) => {
  const { open, handleClose, children } = props;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableAutoFocus
      disableEnforceFocus
    >
      <div ref={ref}>{children}</div>
    </Modal>
  );
});
