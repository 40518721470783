import API, { ApiRoutes } from 'components/API';
import { Config } from 'configs';

export const uploadImageToMlTrainingSet = async (data, alertController) => {
  try {
    const response = await API.post(
      ApiRoutes.UploadImageToTrainingSetRequest,
      data,
    );

    if (response) {
      if (alertController) {
        alertController.success('Image has been uploaded', false);
      }
      return true;
    }
    return false;
  } catch (e) {
    Config.SHOW_LOGS && console.log('Cannot upload', e);
    if (alertController) {
      alertController.error('Cannot upload, ' + e.message, false);
    }
  }
};

export const getDataSets = async (modelType) =>
  await API.get(ApiRoutes.dataSets, {
    params: {
      model_type: modelType,
    },
  });
export const getAnomaliesResults = async (
  clientName,
  model,
  dateRange,
  reportType,
  algorithm,
) =>
  await API.get(ApiRoutes.getAnomaliesResults, {
    params: {
      client: clientName,
      model,
      date_range: dateRange,
      report_type: reportType,
      algorithm,
    },
  });
export const getDailyAnomalies = async (clientId) =>
  await API.get(`${ApiRoutes.getDailyAnomalies}?client=${clientId}`);

export const getScannerAnomalies = async (params) =>
  await API.get(ApiRoutes.scannerAnomalies, {
    params: { ...params },
  });

export const getDataSetById = async (id) =>
  await API.get(`${ApiRoutes.dataSets}/${id}`);

export const createDataSet = async (data) =>
  await API.post(ApiRoutes.dataSets, {
    ...data,
  });

export const deleteDataSet = async (id) =>
  await API.delete(`${ApiRoutes.dataSets}/${id}`);

export const deleteImageFromDataSet = async (dataSetId, category, imageKey) =>
  await API.post(`${ApiRoutes.dataSetImages}`, {
    dataSetId,
    category,
    imageKey,
  });
