import React from 'react';

const PortalLink = ({ image }) => {
  const getPortalLinklByClient = () => {
    let portalLink = '#';

    switch (image.client) {
      case 'wba':
        portalLink = `https://wba-eu-london.fitskin.com/scan_sessions?search=${image.id}`;
        break;
      case 'sephora_na':
        portalLink = `https://sephora-na-v2.fitskin.com/scan_sessions?search=${image.id}`;
        break;
      default:
        break;
    }

    return portalLink;
  };

  return (
    <span>
      SSID:{' '}
      <a href={getPortalLinklByClient()} target="__blank">
        {image.id}
      </a>
    </span>
  );
};

export default PortalLink;
