/* eslint-disable react-hooks/rules-of-hooks */
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Context as AuthContext } from 'contexts/AuthContext';
import { history } from 'utils/history';

export const getUserMenuLinks = () => {
  const { t } = useTranslation();
  const { state: authState, logout } = useContext(AuthContext);
  const { token } = authState;

  return [
    // {
    //   title: 'Model - XPOL',
    //   onClick: () => history.push('/jobs/xpol'),
    //   includeDivider: true,
    // },
    // {
    //   title: 'Model - UV',
    //   onClick: () => history.push('/jobs/uv'),
    //   includeDivider: true,
    // },
    // {
    //   title: 'Data Sets',
    //   onClick: () => history.push('/datasets'),
    //   includeDivider: true,
    // },
    // {
    //   title: 'Feedback',
    //   onClick: () => history.push('/feedback'),
    //   includeDivider: true,
    // },
    {
      title: t('logout'),
      onClick: () => logout('/jobs/xpol', token),
      includeDivider: true,
    },
  ];
};
