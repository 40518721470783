import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { history } from 'utils/history';
import { getDailyAnomalies } from 'api';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';

export const AnomalousScannersMenu = ({ location }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      //marginLeft: theme.spacing(2),
      border: '1px solid rgba(0, 0, 0, 0.12)',
      padding: '10px',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paperLeft: {
      padding: theme.spacing(1),
      textAlign: 'left',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
    },
    paperRight: {
      padding: theme.spacing(1),
      textAlign: 'right',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
      // textDecoration: 'underline',
    },
    pageTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 24,
      fontWeight: 'bold',
    },
    sectionTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 20,
      marginTop: 20,
      fontWeight: 'bold',
    },
    warning: {
      color: 'red',
      padding: theme.spacing(1),
      fontSize: 16,
      fontWeight: 'bold',
    },
    header: {
      backgroundColor: 'lightgray',
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));
  const classes = useStyles();
  const [jsonResponse, setJsonResponse] = useState(null);

  const fetchChart = useCallback(async (chartId, placementId) => {
    const sdk = new ChartsEmbedSDK({
      baseUrl: 'https://charts.mongodb.com/charts-warehouse-uhqyz',
    });

    const chart = sdk.createChart({
      chartId: chartId,
      showAttribution: false,
      filter: {},
      autoRefresh: false,
      maxDataAge: 86400,
    });

    await chart.render(document.getElementById(placementId));
  }, []);

  const sephoraNaXpolDailyAnomalies = [
    ...(jsonResponse?.filter(
      (x) => x.client === 'sephora_na' && x.model === 'xpol',
    ) || []),
  ];
  const sephoraNaUvDailyAnomalies = [
    ...(jsonResponse?.filter(
      (x) => x.client === 'sephora_na' && x.model === 'uv',
    ) || []),
  ];

  const wbaXpolDailyAnomalies = [
    ...(jsonResponse?.filter((x) => x.client === 'wba' && x.model === 'xpol') ||
      []),
  ];
  const wbaUvDailyAnomalies = [
    ...(jsonResponse?.filter((x) => x.client === 'wba' && x.model === 'uv') ||
      []),
  ];

  const fetchResults = async () => {
    try {
      // setIsLoading(true);
      const response = await getDailyAnomalies();
      setJsonResponse(response);
    } catch (error) {
      console.error('Error fetching anomaly results:', error);
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchCharts = async () => {
      fetchChart('e8e62378-301a-40d0-bb41-d66199beff4e', 'wba_summary_xpol');
      fetchChart('40ba610c-4d08-47d4-a5c7-54e5bd7916ba', 'wba_summary_uv');
      fetchChart(
        'd7f1ee2b-6d92-4cf3-8121-77b9212a8624',
        'sephora_na_summary_xpol',
      );
      fetchChart(
        '3ff638b8-26a6-458f-af29-59d331cd18bd',
        'sephora_na_summary_uv',
      );
    };
    fetchCharts();
    fetchResults();

    // wba
  }, []);

  const renderDataXpol = (client, anomaliesAggregatedInformation) => {
    let rows = [];
    if (
      anomaliesAggregatedInformation &&
      anomaliesAggregatedInformation.length > 0
    ) {
      anomaliesAggregatedInformation.forEach((anomaly) => {
        let outputCells = [];
        outputCells.push({
          value: anomaly.date,
          date: anomaly.date,
        });
        outputCells.push({
          value: anomaly.anomalies?.find((x) => x.anomaly === 'foreign_object')
            ?.count,
          date: anomaly.date,
          propertyName: 'foreign_object',
        });
        outputCells.push({
          value: anomaly.anomalies?.find(
            (x) => x.anomaly === 'incorrect_placement',
          )?.count,
          date: anomaly.date,
          propertyName: 'incorrect_placement',
        });
        outputCells.push({
          value: anomaly.anomalies?.find((x) => x.anomaly === 'out_of_focus')
            ?.count,
          date: anomaly.date,
          propertyName: 'out_of_focus',
        });
        outputCells.push({
          value: anomaly.anomalies?.find((x) => x.anomaly === 'not_skin')
            ?.count,
          date: anomaly.date,
          propertyName: 'not_skin',
        });
        rows.push(outputCells);
      });
    }

    return rows.map((row, index) => (
      <div
        key={index}
        style={{
          display: 'grid',
          gridTemplateColumns: '180px 150px 150px 150px 150px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        {row.map((cell, cellIndex) => (
          <Grid
            key={cellIndex}
            item
            className={classes.cell}
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              const { date, propertyName } = cell;
              history.push(
                `/anomalies-detection-day-results?name=${client}&type=daily&model=xpol&date=${date}&propertyName=${propertyName}`,
              );
            }}
          >
            <Paper
              className={cellIndex >= 1 ? classes.paperRight : classes.paper}
            >
              {cell.value || 0}
            </Paper>
          </Grid>
        ))}
      </div>
    ));
  };

  const renderDataUv = (client, anomaliesAggregatedInformation) => {
    let rows = [];
    if (
      anomaliesAggregatedInformation &&
      anomaliesAggregatedInformation.length > 0
    ) {
      anomaliesAggregatedInformation.forEach((anomaly) => {
        let outputCells = [];
        outputCells.push({
          value: anomaly.date,
          date: anomaly.date,
        });
        outputCells.push({
          value: anomaly.anomalies?.find((x) => x.anomaly === 'black')?.count,
          date: anomaly.date,
          propertyName: 'black',
        });
        outputCells.push({
          value: anomaly.anomalies?.find((x) => x.anomaly === 'lift_black')
            ?.count,
          date: anomaly.date,
          propertyName: 'lift_black',
        });
        outputCells.push({
          value: anomaly.anomalies?.find((x) => x.anomaly === 'lift_off')
            ?.count,
          date: anomaly.date,
          propertyName: 'lift_off',
        });
        rows.push(outputCells);
      });
    }

    return rows.map((row, index) => (
      <div
        key={index}
        style={{
          display: 'grid',
          gridTemplateColumns: '180px 150px 150px 150px 150px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        {row.map((cell, cellIndex) => (
          <Grid
            key={cellIndex}
            item
            className={classes.cell}
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              const { date, propertyName } = cell;
              history.push(
                `/anomalies-detection-day-results?name=${client}&type=daily&model=uv&date=${date}&propertyName=${propertyName}`,
              );
            }}
          >
            <Paper
              className={cellIndex >= 1 ? classes.paperRight : classes.paper}
            >
              {cell.value || 0}
            </Paper>
          </Grid>
        ))}
      </div>
    ));
  };

  const renderHeaderUv = (dates) => {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '180px 150px 150px 150px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <div>
          <Paper className={classes.header}>
            <span>Date</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Black</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Lift black</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Lift off</span>
          </Paper>
        </div>
      </div>
    );
  };

  const renderHeaderXpol = (dates) => {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '180px 150px 150px 150px 150px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <div>
          <Paper className={classes.header}>
            <span>Date</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Foreign Object</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Incorrect Placement</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Out of focus</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Not Skin</span>
          </Paper>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <div>
          <div>
            <Typography className={classes.pageTitle} align="left">
              Daily anomalies
            </Typography>
          </div>
          <div>
            <Typography className={classes.sectionTitle} align="left">
              UV
            </Typography>
          </div>
          <div>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <Typography className={classes.categoryTitle} align="left">
                Sephora
              </Typography>
            </div>
            <Grid item spacing={0}>
              {renderHeaderUv()}
              {renderDataUv('sephora_na', sephoraNaUvDailyAnomalies)}
            </Grid>
            <div style={{ display: 'flex', marginTop: 30, gap: '20px' }}>
              <div
                id="sephora_na_summary_uv"
                style={{
                  width: '700px',
                  height: '550px',
                  display: 'block',
                }}
              />
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <Typography className={classes.categoryTitle} align="left">
                WBA
              </Typography>
            </div>
            <Grid item spacing={0}>
              {renderHeaderUv()}
              {renderDataUv('wba', wbaUvDailyAnomalies)}
            </Grid>

            <div style={{ display: 'flex', marginTop: 30, gap: '20px' }}>
              <div
                id="wba_summary_uv"
                style={{
                  width: '700px',
                  height: '550px',
                  display: 'block',
                }}
              />
            </div>
          </div>

          <div>
            <Typography className={classes.sectionTitle} align="left">
              XPOL
            </Typography>
          </div>

          <div>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <Typography className={classes.categoryTitle} align="left">
                Sephora
              </Typography>
            </div>
            <Grid item spacing={0}>
              {renderHeaderXpol()}
              {renderDataXpol('sephora_na', sephoraNaXpolDailyAnomalies)}
            </Grid>

            <div style={{ display: 'flex', marginTop: 30, gap: '20px' }}>
              <div
                id="sephora_na_summary_xpol"
                style={{
                  width: '700px',
                  height: '550px',
                  display: 'block',
                }}
              />
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <Typography className={classes.categoryTitle} align="left">
                WBA
              </Typography>
            </div>
            <Grid item spacing={0}>
              {renderHeaderXpol()}
              {renderDataXpol('wba', wbaXpolDailyAnomalies)}
            </Grid>

            <div style={{ display: 'flex', marginTop: 30, gap: '20px' }}>
              <div
                id="wba_summary_xpol"
                style={{
                  width: '700px',
                  height: '550px',
                  display: 'block',
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <Typography
            className={classes.pageTitle}
            style={{ width: '100%', marginTop: 30 }}
            align="left"
          >
            Historical data
          </Typography>
        </div>
        <div>
          <Typography className={classes.sectionTitle} align="left">
            XPOL
          </Typography>
        </div>
        <div style={{ width: '100%', marginTop: 30 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Client</TableCell>
                  <TableCell>Frequency</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Sephora NA
                  </TableCell>
                  <TableCell>Monthly</TableCell>
                  <TableCell>
                    <a
                      href={`/anomalies-detection-results?name=sephora_na&type=monthly&model=xpol`}
                    >
                      View
                    </a>
                  </TableCell>
                </TableRow>
                {/* <TableRow>
                <TableCell component="th" scope="row">
                  Sephora NA
                </TableCell>
                <TableCell>Weekly</TableCell>
                <TableCell>
                  <a
                    href={`/anomalies-detection-results?name=sephora_na&type=weekly&model=xpol`}
                  >
                    View
                  </a>
                </TableCell>
              </TableRow> */}
                <TableRow>
                  <TableCell component="th" scope="row">
                    WBA
                  </TableCell>
                  <TableCell>Monthly</TableCell>
                  <TableCell>
                    <a
                      href={`/anomalies-detection-results?name=wba&type=monthly&model=xpol`}
                    >
                      View
                    </a>
                  </TableCell>
                </TableRow>
                {/* <TableRow style={{ border: 0 }}>
                <TableCell component="th" scope="row">
                  WBA
                </TableCell>
                <TableCell>Weekly</TableCell>
                <TableCell>
                  <a
                    href={`/anomalies-detection-results?name=wba&type=weekly&model=xpol`}
                  >
                    View
                  </a>
                </TableCell>
              </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div>
          <Typography className={classes.sectionTitle} align="left">
            UV
          </Typography>
        </div>
        <div style={{ width: '100%', marginTop: 30 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Client</TableCell>
                  <TableCell>Frequency</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Sephora NA
                  </TableCell>
                  <TableCell>Monthly</TableCell>
                  <TableCell>
                    <a
                      href={`/anomalies-detection-results?name=sephora_na&type=monthly&model=uv`}
                    >
                      View
                    </a>
                  </TableCell>
                </TableRow>
                {/* <TableRow>
                <TableCell component="th" scope="row">
                  Sephora NA
                </TableCell>
                <TableCell>Weekly</TableCell>
                <TableCell>
                  <a
                    href={`/anomalies-detection-results?name=sephora_na&type=weekly&model=uv`}
                  >
                    View
                  </a>
                </TableCell>
              </TableRow> */}
                <TableRow>
                  <TableCell component="th" scope="row">
                    WBA
                  </TableCell>
                  <TableCell>Monthly</TableCell>
                  <TableCell>
                    <a
                      href={`/anomalies-detection-results?name=wba&type=monthly&model=uv`}
                    >
                      View
                    </a>
                  </TableCell>
                </TableRow>
                {/* <TableRow style={{ border: 0 }}>
                <TableCell component="th" scope="row">
                  WBA
                </TableCell>
                <TableCell>Weekly</TableCell>
                <TableCell>
                  <a
                    href={`/anomalies-detection-results?name=wba&type=weekly&model=uv`}
                  >
                    View
                  </a>
                </TableCell>
              </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};
