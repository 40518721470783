import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
  },
});

export const CircularLoader = ({ color, size, customStyle, doNotCenter }) => {
  const classes = useStyles();

  var style = customStyle || classes.root;

  return (
    <Grid container className={style} spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent={doNotCenter ? 'flex-start' : 'center'}>
          <CircularProgress color={color} size={size} />
        </Grid>
      </Grid>
    </Grid>
  );
};
