import React, { useContext } from 'react';
import { SnackbarProvider } from 'notistack';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Container, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AppHeader } from 'components/Navigation/AppHeader';
import { Footer } from 'components/Navigation/Footer';
import { getNavLinks } from 'components/Navigation/links';
import { locales } from 'locales/locales';
import { Context as AuthContext } from 'contexts/AuthContext';
import { Config } from 'configs';
import { SnackbarUtilsConfigurator } from 'utils/snackbarUtils';

const useStyles = makeStyles((theme) => ({
  appContainer: {
    backgroundColor: 'white',
    display: 'table',
    alignItems: 'flex-start',
  },
  main: {
    display: 'table',
    width: '100%',
    backgroundColor: 'white',
  },
  contentContainer: {
    //display: 'inline-flex',
    display: 'flex',
    flexGrow: 1,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    backgroundColor: 'white',
  },
}));

export const BaseLayout = ({ children, topBanner }) => {
  const { state } = useContext(AuthContext);
  const { user } = state;
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { i18n } = useTranslation();
  const navLinks = getNavLinks();

  const userRole = user?.role || Config.ROLES.UNAUTHENTICATED;

  const filteredNavLinks = navLinks.filter(
    (navItem) =>
      navItem.languages.includes(i18n.language) &&
      navItem.roles.includes(userRole),
  );

  const Header = () => (
    <AppHeader
      locales={locales}
      matches={matches}
      navLinks={filteredNavLinks}
      topBanner={topBanner}
    />
  );

  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={4000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <SnackbarUtilsConfigurator />
      <Container className={classes.appContainer} maxWidth="xl">
        <Header />
        <main className={classes.main}>
          <div className={classes.contentContainer}>{children}</div>
        </main>
        {/* <Footer /> */}
      </Container>
    </SnackbarProvider>
  );
};
