import React, { useEffect, useState } from 'react';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CircularLoader } from 'components/Controls';
import { getDataSets, getFedback, resolveFeedback } from 'api';
import queryString from 'query-string';
import ImageComponent from 'components/image';
import AddToTrainingModal from '../AddToTrainingModal';
import s3ParseUrl from 's3-url-parser';
import AnomalyItem from '../AnomalyItem';
import AnomalyUvItem from '../AnomalyUvItem';
import Select from 'react-select';
import { Form } from 'components/Controls';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //marginLeft: theme.spacing(2),
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '10px',
    minWidth: 400,
    maxWidth: 400,
  },
  header: {
    backgroundColor: 'lightgray',
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paperLeft: {
    padding: theme.spacing(1),
    textAlign: 'left',
    paddingLeft: 20,
    color: theme.palette.text.secondary,
  },
  paperRight: {
    padding: theme.spacing(1),
    textAlign: 'right',
    paddingLeft: 20,
    color: theme.palette.text.secondary,
    // textDecoration: 'underline',
  },
  pageTitle: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    fontSize: 24,
    fontWeight: 'bold',
  },
  categoryTitle: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    fontSize: 18,
    fontWeight: 'bold',
  },
  cell: {
    width: '100%',
  },
  thresholdCell: {
    ...theme.legendCell,
    backgroundColor: '#e2f1f8',
  },
  thresholdCellAlt: {
    ...theme.legendCell,
    backgroundColor: '#c1d5e0',
  },
  selectInput: {
    //zIndex: 100,
    marginTop: 16,
    marginBottom: 8,
    marginLeft: 10,
    marginRight: 10,
  },
  paperBold: {
    width: '110px',
    padding: theme.spacing(1),
    fontSize: 14,
    backgroundColor: '#e0ffee',
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  csvLink: {
    marginTop: theme.spacing(2),
  },
  modal: {
    padding: 60,
    position: 'absolute',
    width: 500,
    height: 700,
    display: 'block',
    boxShadow: theme.shadows[5],
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
  },
}));

export const AnomaliesFeedbackScreen = ({ location }) => {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [dataSets, setDataSets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isResolving, setIsResolving] = useState(false);
  const [selectedImageItem, setSelectedImageItem] = useState(null);
  const [
    showImageUploadToTrainingSetModal,
    setShowImageUploadToTrainingSetModal,
  ] = useState(false);

  const hasData = data != null && data.length > 0;
  const qsParams = queryString.parse(location.search);
  const [selectedCustomer, setSelectedCustomer] = useState(
    qsParams.client || undefined,
  );

  const [customers] = useState([
    { value: 'wba', label: 'WBA' },
    { value: 'sephora_na', label: 'Sephora NA' },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const data = await getFedback(selectedCustomer || '');
      setData(data || []);
      setIsLoading(false);
    };

    fetchData();
  }, [selectedCustomer]);

  useEffect(() => {
    (async function fetchDataSets() {
      try {
        const ds = await getDataSets();
        setDataSets(ds);
      } catch (e) {
        console.log(e);
      } finally {
      }
    })();
  }, []);

  const renderImageDataFromUrl = (image) => {
    const {
      region,
      bucket: bucket_name,
      key: image_path,
    } = s3ParseUrl(image.url);
    return { bucket_name, region, image_path };
  };

  const setAsResolved = async (id) => {
    setIsResolving(true);
    const response = await resolveFeedback(id);

    if (response) {
      //const data = await getFedback(selectedCustomer || '');
      // setData(data || []);
      // redirect to '/feedback' + selectedCustomer ? `?client=${selectedCustomer}` : '';

      window.location.href =
        '/feedback' + (selectedCustomer ? `?client=${selectedCustomer}` : '');
    }
    setIsResolving(false);
  };

  const renderGrid = () => {
    if (!hasData) {
      return null;
    }

    return (
      <Grid item spacing={0}>
        <div>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <Typography className={classes.categoryTitle} align="left">
              Feedback loop {qsParams.client ? `from ${qsParams.client}` : ''}
            </Typography>
          </div>
          <Grid item spacing={0}>
            {data.map((item, index) => {
              return (
                <Paper className={classes.paper} key={index}>
                  <Grid container spacing={0}>
                    <Grid item lg={3} xs={3} sm={3}>
                      <Typography className={classes.paperLeft}>
                        {item.type === 'uv' && (
                          <AnomalyUvItem
                            item={item.anomalies}
                            highlightedProperty={undefined}
                          />
                        )}
                        {item.type === 'anomalies' && (
                          <AnomalyItem
                            item={item.anomalies}
                            highlightedProperty={undefined}
                          />
                        )}
                        {/* {renderMeasures(item.type, item.anomalies)} */}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.paperRight}>
                        <ImageComponent
                          image={{
                            ...renderImageDataFromUrl(item.image),
                            client: item.client,
                          }}
                        />
                        <Button
                          type="buttton"
                          variant="contained"
                          color="primary"
                          style={{ marginTop: 10, width: '100%' }}
                          onClick={() => {
                            setSelectedImageItem(item);
                            setShowImageUploadToTrainingSetModal(true);
                          }}
                        >
                          Add To Training Set...
                        </Button>
                        <Button
                          type="buttton"
                          variant="contained"
                          color="primary"
                          style={{ marginTop: 10, width: '100%' }}
                          disabled={isResolving}
                          onClick={() => {
                            setAsResolved(item.id);
                            console.log('resolve', item.id);
                          }}
                        >
                          Resolve...
                        </Button>
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              );
            })}
          </Grid>
        </div>
      </Grid>
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <div className={classes.root}>
        <Form>
          <Grid container direction="column">
            <Grid item alignItems="center">
              <Select
                onChange={(selectedItem) => {
                  setSelectedCustomer(selectedItem.value);
                }}
                value={customers.find((c) => c.value === selectedCustomer)}
                placeholder="Select client"
                className={classes.selectInput}
                options={customers}
              />
            </Grid>
          </Grid>
        </Form>
      </div>
      <div style={{ marginTop: 30 }}></div>
      <div style={{ marginTop: 10 }}>
        {isLoading && (
          <CircularLoader
            doNotCenter
            customStyle={{
              flexGrow: 1,
              width: '100vw',
            }}
          />
        )}
      </div>
      {!isLoading && renderGrid()}
      {showImageUploadToTrainingSetModal && selectedImageItem != null && (
        <AddToTrainingModal
          isOpen={showImageUploadToTrainingSetModal}
          onClose={() => {
            setSelectedImageItem(null);
            setShowImageUploadToTrainingSetModal(false);
          }}
          client={selectedImageItem.client}
          dataSets={dataSets}
          anomalies={selectedImageItem.anomalies}
          selectedImageItem={{
            ...renderImageDataFromUrl(selectedImageItem.image),
          }}
        />
      )}
    </div>
  );
};
