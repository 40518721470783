export const locales = [
  {
    title: 'en',
    language: 'en',
  },
  {
    title: 'it',
    language: 'it',
  },
];
