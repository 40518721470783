import React from 'react';

const logoStyles = {
  image: {
    float: 'left',
    maxWidth: '100%',
    margin: '10px',
    clear: 'none',
    display: 'block',
  },
};

export const Logo = () => {
  const logoSource = process.env.REACT_APP_CUSTOMER_LOGO_FILE_NAME
    ? `${process.env.PUBLIC_URL}/${process.env.REACT_APP_CUSTOMER_LOGO_FILE_NAME}`
    : 'https://fitskin.co/img/assets/logo-dark.png';

  const logoSoureWidth = process.env.REACT_APP_CUSTOMER_LOGO_WIDTH
    ? process.env.REACT_APP_CUSTOMER_LOGO_WIDTH
    : '210px';

  const logoSoureHeight = process.env.REACT_APP_CUSTOMER_LOGO_HEIGHT
    ? process.env.REACT_APP_CUSTOMER_LOGO_HEIGHT
    : '100px';

  return (
    <a href="/">
      <img
        style={{
          ...logoStyles.image,
          ...{ width: logoSoureWidth, height: logoSoureHeight },
        }}
        src={`${logoSource}`}
        alt="logo"
      />
    </a>
  );
};
